import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle `

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: boder-box;
        font-family: 'Poppins', sans-serif;
        font-weight: 100;
        -webkit-font-smoothing: antialised !important;
    }

    html, body, #root{
    }

    body{
        background-color: #FAFAFA!important;
    }

    body, input, button, textarea, select{
        background-color: #FFF;
        font-size: 14px;
        color: #7D7D7D;
    }

    body{
        background: #FFF;
    }

    button{
        cursor: pointer;
    }

    a{
        color: #1F2F59;
        text-decoration: none;
        cursor: pointer;
    }

    #baseContainer, #minhaConsultaListaContainer{
        width: 70%;
        height: 100%;
        margin: 65px 0 0 0;
        padding: 30px;
        position: fixed;
        top: 0;
        left: 0;        
        z-index: 999;
        overflow-y: scroll;
    }

    #buscaContainer, #resultadoContainer, #resultadoFarmaciaContainer, #farmaciaContainer, #comprarContainer, #comprarConfirmacaoContainer, #medicoContainer, #agendaContainer, #checkoutContainer, #confirmacaoContainer{
        width: 70%;
        height: 100%;
        background: #FFF;
        margin: 65px 0 0 0;
        padding: 30px;
        position: fixed;
        top: 0;
        left: 0;        
        z-index: 999;
        overflow-y: scroll;
    }

    

    #resultadoContainer, #resultadoFarmaciaContainer, #farmaciaContainer, #comprarContainer, #comprarConfirmacaoContainer, #medicoContainer, #agendaContainer, #checkoutContainer, #confirmacaoContainer, #minhaConsultaDetalhesContainer, #resumoDataHorario, #resumoDataHorario2, #botaoFinalizarPagamento, #lightbox-confirmacao{
        display: none;
    }

    .carousel .slide{
        background: none!important;
    }
    .carousel .slide a{
        display: block;
        width: 100%;
        height: 100%;
    }

    .tituloSessao {
        font-size: 26px!important;
        color: #333333!important;
        margin: 10px 10px 30px 10px!important;
    }

    #resultadoContainer{
        background: #FAFAFA;
        padding: 0;
    }

    #baseContainer{
        width: 100%;
        background: #FAFAFA;
        padding: 0;
    }


    #lightbox-bg{
        background: #000;
        width: 100%;
        height: 150%;
        position: absolute;
        top: 0;
        left: 0;        
        z-index: 99999999999999999999999999;
        filter:alpha(opacity=70);
        opacity: 0.7;
        -moz-opacity:0.7;
        -webkit-opacity:0.7;
        display: none;
    }
    #lightbox-bg a{
        witdh: 100%;
        height: 100%;
        display: block;
    }


    #lightbox-loading{
        width: 100px;
        position: relative;
        z-index: 999999999999999999999999999;
        left: 0;
        top: 0;
        display: none;
    }

    #lightbox-loading img{
        width: 100px;
    }


    #lightbox{
        background: #FFF;
        width: 800px;
        position: relative;
        z-index: 999999999999999999999999999;
        padding: 20px;
        margin: 40px auto;
        display: none;
    }
    #lightbox #lightbox-checkout {
        display: table;
    }

    #lightbox address{
        background: #F8F8F8;
        padding: 20px;
        margin: 20px 0;
        border-top: 1px solid #CCC;
        font-style: normal;
    }

    #lightbox address p{
        color: #333333;
        font-size: 14px;
    }
    #lightbox address h2{
        font-size: 13px!important;
    }

    #lightbox blockquote{
        text-align: center;
        color: #999999;
        font-size: 12px;
        margin-top: 20px;
    }

    #lightbox blockquote p{
        margin-top: 10px;
        color: #999999;
        font-size: 10px;
    }

    #lightbox hr{
        border: none;
        border-top: 1px solid #CCC;
        margin: 10px 0;
    }

    #lightbox h1{
        font-size: 20px;
        color: #13365C;
        font-weight: bold;
        margin-bottom: 10px;
    }
    #lightbox h2{
        font-size: 16px;
        color: #13365C;
        text-transform: uppercase;
    }
    #lightbox h3{
        font-size: 20px;
        color: #1F2F59;
        background: #F8F8F8;
        text-align: center;
        padding: 10px 0;
        margin: 10px 0;
    }
    #lightbox h4{
        font-size: 13px;
        color: #00B4BE;
        margin: 5px 0;
    }

    #lightbox p{
        color: #999999;
        font-size: 13px;
        margin-top: 3px;
    }

    #lightbox img{
        width: 80px;
        height: 80px;
        float: left;
        margin-right: 15px;
    }

    #lightbox .col-lef-50{
        width: 360px;
        float: left;
        display: block;
    }
    #lightbox .col-rig-50{
        width: 400px;
        margin-left: 40px;
        float: right;
        display: block;
    }

    #lightbox #mensagemMeioPagamento{
        text-align: center;
        display: none;
    }
    #lightbox #mensagemMeioPagamento a{
        font-size: 16px;
        padding: 10px;
        border: none;
        border-radius: 4px;    
        background: #00B4BE;
        color: #FFF;
        text-transform: uppercase;
        text-align: center;
        display: block;
        margin-top: 20px;
    }

    .titulo{
        color: #373a3c!important;
        font-size: 16px!important;
        text-transform: uppercase;
        border-bottom: 1px solid #CCC;
        padding: 10px;
        margin-bottom: 20px!important;
    }
    .titulo a{
        float: right;
    }

    .subtitulo{
        color: #373a3c!important;
        font-size: 14px!important;
        text-transform: uppercase;
        border-bottom: 1px solid #CCC;
        padding: 10px;
        margin: 20px 0!important;
    }

    #formProdutos {
        display: none;
    }


    @media (max-width: 600px) {

        #buscaContainer, #resultadoContainer, #resultadoFarmaciaContainer, #farmaciaContainer, #comprarContainer, #comprarConfirmacaoContainer, #medicoContainer, #agendaContainer, #checkoutContainer, #confirmacaoContainer{
            width: 100%;
            padding: 15px;
        }

        .tituloSessao {
            margin: 10px 10px 30px 0!important;
        }

        #lightbox{
            width: 80%;
            margin: 20px auto;
        }

        #lightbox .col-lef-50, #lightbox .col-rig-50{
            width: 100%;
            margin-left: 0;
            float: none;
            display: block;
        }

        #lightbox .col-rig-50{
            margin-top: 20px;
        }


    }

`;