import styled from 'styled-components';

export const NavBar = styled.div `

    background: #FFF;
    color: #00B4BE;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
    box-shadow: 0px 2px 4px #0000000D;

    img {
        width: 100px;
        margin-right: 50px;
    }

    @media (max-width: 600px) {
        display: none;
    }

`;

export const NavBarMobile = styled.div `

    background: #FFF;
    color: #00B4BE;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
    display: none;

    img {
        width: 100px;
        margin-left: 60px;
    }

    @media (max-width: 600px) {
        display: block;
    }

`;

export const SubMenu = styled.div `

    background: #eef8ff;
    color: #00B4BE;
    position: fixed;
    top: 63px;
    left: 0;
    width: 100%;
    z-index: 999999999999999999999;
    padding: 20px;
    display: none;

    a{
        display: block;
        margin-bottom: 15px;
    }

    p {
        border-top: 1px solid #4c5c68;
        margin-top: 20px;
        padding-top: 20px;
        width: 90%;
        color: #4c5c68;
        font-size: 16px;
    }

    p a{
        color: #4c5c68;
        font-size: 14px;
    }

`;


export const Usuario = styled.div `

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 50px;

    div {
        float: left;
        margin-top: 10px;
    }

    img{
        width: 50px;
        height: 50px;
        border-radius: 6px;
        margin-right: 15px;
        float: right:
    }

    p{
        text-align: right;
        font-size: 16px;
        padding-right: 20px;
        color: #13365C;
    }

    a{
        color: #13365C;
        padding: 10px 20px 0 0;
        font-size: 13px;
    }

`;


export const Container = styled.div `

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;

    @media (max-width: 600px) {
        width: 100%;
    }

`;


export const MenuUsuario = styled.div `

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    display: none;

    div {
        float: left;
        margin-top: 5px;
    }

    @media (max-width: 600px) {
        width: 100%;
    }

`;

export const MenuItem = styled.div `

    color: #333333;
    text-align: left;
    font-size: 14px;
    align-items: center;
    display: flex;
    padding: 0 15px;

`;

export const MenuItemButton = styled.div `

    background: #1F2F59 0% 0% no-repeat padding-box;
    color: #FFF;
    text-align: left;
    font-size: 12px;
    align-items: center;
    display: flex;
    padding: 7px 30px;
    border-radius: 7px;
    text-transform: uppercase;
    margin-top: 0!important;
    float: left;

`;