import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FaBars } from 'react-icons/fa';

import { NavBar, NavBarMobile, Usuario, MenuUsuario, Container, MenuItem, MenuItemButton, SubMenu } from './styles';

import Logo from '../../images/logo-vevee-color.svg';

class Nav extends Component{

    constructor(props){
      super(props);
      this.state = {
        usuarioNome: '',
        setLinks: '',
        submenu: 'none',
        menuLogado: 'none',
        menuDeslogado: 'none'
      };

      let state = this.state;
      this.submenu = this.submenu.bind(this);

      if(localStorage.getItem('@vevee/usuariocodigo')){
        state.usuarioNome = localStorage.getItem('@vevee/usuarionome');
        state.setLinks = <div style={{marginTop:'5px'}}><Link to={'./meuperfil'}>Meu Perfil</Link><Link to={'./login'}>Sair</Link></div>;
        state.menuLogado = 'block';
        this.setState(state);
      }
      else{
        state.usuarioNome = 'Visitante';
        state.setLinks = <div style={{marginTop:'5px'}}><Link to={'./login'}>Login</Link><Link to={'./cadastro'}>Faça seu cadastro</Link></div>;
        state.menuDeslogado = 'block';
        this.setState(state);
      }
    }

    submenu() {

      let state = this.state;

      if(state.submenu == 'none'){
        document.getElementById('submenu').style.setProperty('display', 'block');
        state.submenu = 'block';
      }
      else{
        document.getElementById('submenu').style.setProperty('display', 'none');
        state.submenu = 'none';
      }
      this.setState(state);
      
    }
  
    render(){
      let state = this.state;

      return(
        <>
          <NavBar>
            <Container>
                <img src={ Logo } alt="Vevee" />

                <a href='https://vevee.com.br'><MenuItem>Início</MenuItem></a>
                <Link to={'./'}><MenuItem>Agendar consultas</MenuItem> </Link>
                <Link to={'./minhasconsultas'}><MenuItem id="menuMinhasConsultas"> Minhas Consultas</MenuItem></Link>
            </Container>

            <MenuUsuario id="menuDeslogado" style={{display: state.menuDeslogado}}>
              <div>
              <Link to={'./login'}><MenuItem>Entrar</MenuItem></Link>
              <Link to={'./cadastro'}><MenuItemButton>Cadastre-se</MenuItemButton></Link>
              <Link to={'./profissionais'}><MenuItem>Para médicos / clinicas</MenuItem></Link>
              </div>
            </MenuUsuario>
            <Usuario id="menuLogado" style={{display: state.menuLogado}}>
                <div>
                  <p>Olá, {state.usuarioNome}</p>
                  <Link to={'./meuperfil'}>Meu Perfil</Link>
                  <Link to={'./login'}>Sair</Link>
                </div>
                <img src="https://i0.wp.com/www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png?fit=256%2C256&quality=100&ssl=1" alt={state.usuarioNome} /><br />
            </Usuario>
          </NavBar>

          <NavBarMobile>
            <Container>
                <Link onClick={this.submenu}><MenuItem><FaBars /></MenuItem></Link>
                <img src={ Logo } alt="Vevee" />
            </Container>            
          </NavBarMobile>

          <SubMenu id="submenu">
                <Link to={'https://vevee.com.br'}>Início</Link>
                <Link to={'./'}>Agendar Consulta</Link>
                <Link to={'./minhasconsultas'}>Minhas Consultas</Link>

                <p>{state.usuarioNome}<br /><br />
                {state.setLinks}
                </p>
          </SubMenu>
        </>
      );
    }
  
  }
  
  export default Nav;