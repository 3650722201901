import styled from 'styled-components';

import Bg from '../../images/bg-home.png';
import LogoPinDentista from '../../images/map-pin-dentista.png';
import LogoPinFarmacia from '../../images/map-pin-farmacia.png';
import LogoPinLaboratorio from '../../images/map-pin-laboratorio.png';
import LogoPinMedico from '../../images/map-pin-medico.png';

export const Header = styled.div `

    background: url( ${Bg} ) no-repeat #F5F5F5;
    background-position: top center;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg{
        color: #FFF;
        font-size: 20px;
    }

`;

export const Container = styled.div `
    
    width: 672px;
    margin: 0 auto;

    svg{
        margin: 0 0 20px 0;
        color: #13365C;
        font-size: 30px;
    }

    @media (max-width: 600px) {
        width: 100%;
    }

    #listaProdutos {
        width: 300px;
        margin: 0 auto;
    }
    #listaProdutos li{
        border-bottom: 1px solid #CCC;
        width: 300px;
        text-align: left;
    }
    #listaProdutos li{

    }
    #listaProdutos li a{
        float: right;
    }
    #listaProdutos li svg{
        font-size: 12px;
        color: #666;
    }

`;

export const Form = styled.form `

    text-align: center; 

    .active{
        color: #00B4BE;
    }

    ul{
        margin: 0 auto;
        width: 200px;
    }

    ul li {
        text-align: center;
        font-size: 13px;
        float: left;
        list-style: none;
        margin: 0 40px 20px 0;
    }

    ul li a{
        color: #333333;
        text-transform: uppercase;
    }

    ul li a svg{
        font-size: 25px;
        margin: 0 0 5px 0;
    }

    .div-50{
        width: 300;
        float: left;
        margin: 20px 20px 20px 0;
    }

    input, select, button{
        flex: 1;
        padding: 15px;
        margin: 0 auto 10px;
        border-radius: 4px; 
        border: 1px solid #DDD;
        font-size: 14px;
    }

    input {
        width: 300px;
    }
    select {
        width: 300px;
    }
    button {
        width: 350px;
        background: #1F2F59;
        margin: 0 auto;
        padding: 10px 0;
    }

    label{
        padding: 0 0 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    svg{
        width: 50px;
        margin: 0 0 0 10px;
    }

    img{
        width: 70%;
        margin: 0 auto 10px;
    }


    @media (max-width: 600px) {
        text-align: left; 

        ul li {
            margin: 0 15px 20px 0;
        }
        .div-50{
            width: 100%;
            float: none;
            margin: 10px 0 0 0;
        }
        input, select {
            width: 80%;
        }
        select {
            width: 90%;
        }
        button {
            width: 90%;
            margin: 0;
        }
    }

`;


export const HeaderResultado = styled.div `

    background: #FFF;

    svg{
        margin: 0 10px 0 0;
        color: #333333;
        font-size: 20px;
    }

    h1{
        padding: 20px;
        color: #13365C!important;
        font-size: 20px;
    }


    @media (max-width: 600px) {

        width: 92%;

    }

`;


export const Resultado = styled.div `

    #mensagem{
        margin: 50px;
        font-size: 20px;
    }

`;

export const BuscaItem = styled.button `

    padding: 20px;
    width: 95%;
    border: none;
    margin: 20px 0;
    box-shadow: 0px 2px 4px #0000000D;
    cursor: default;

    div{
        float: left;
        text-align: left;
        width: 48%;
        padding: 5px;
    }

    address{
        background: #F8F8F8;
        padding: 20px;
        margin: 20px 0;
        border-top: 1px solid #CCC;
        font-style: normal;
    }

    address p{
        color: #333333;
        font-size: 14px;
    }

    blockquote{
        text-align: center;
        color: #999999;
        font-size: 12px;
        margin-top: 20px;
        display: none;
    }

    blockquote p{
        margin-top: 10px;
        color: #999999;
        font-size: 10px;
    }

    hr{
        border: none;
        border-top: 1px solid #CCC;
        margin: 10px 0;
    }

    h1{
        font-size: 20px!important;
        color: #13365C;
        font-weight: bold;
        margin-bottom: 10px;
    }
    h2{
        font-size: 16px;
        color: #13365C;
        text-transform: uppercase;
    }
    h3{
        font-size: 13px;
        color: #7D7D7D;
        text-decoration: line-through;
    }
    h4{
        font-size: 13px;
        color: #00B4BE;
        margin: 5px 0;
    }

    p{
        color: #999999;
        font-size: 13px;
        margin-top: 3px;
    }

    span span {
        font-size: 20px;
    }

    img{
        width: 80px;
        height: 80px;
        float: left;
        margin-right: 15px;
    }


    @media (max-width: 600px) {

        width: 92%;

        div{
            float: none;
            text-align: left;
            width: 100%;
            padding: 5px;
        }

        address h2{
            font-size: 13px;
            margin-bottom: 10px;
        }

        address p{
            font-size: 11px;
        }
        
        span{
            float: right;
        }

    }

`;


export const ButtonMais = styled.a `

    flex: 1;
    padding: 10px 15px;
    display: table;
    border: none;
    border-radius: 4px;    
    background: #00B4BE;
    color: #FFF;
    width: 88%;
    margin: 5px auto 0;
    text-transform: uppercase;
    text-align: center;

`;



export const SubmitButton = styled.button `

    flex: 1;
    padding: 10px 15px;
    display: table;
    border: none;
    border-radius: 4px;    
    background: #00B4BE;
    color: #FFF;
    width: 88%;
    margin: 5px auto 0;
    text-transform: uppercase;

`;

export const Map = styled.div `

    width: 45%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;

    @media (max-width: 600px) {
        display: none;
    }

`;
export const PinAtual = styled.div `

    background: url('https://i0.wp.com/www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png?fit=256%2C256&quality=100&ssl=1') no-repeat;
    background-position: top center;
    background-size: 30px 30px;
    border: 3px solid #666;
    border-radius: 30px;
    width: 30px;
    height: 30px;

`;
export const PinDentista = styled.button `

    background: url( ${LogoPinDentista} ) no-repeat;
    background-position: top center;
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    border: none;

`;
export const PinFarmacia = styled.button `

    background: url( ${LogoPinFarmacia} ) no-repeat;
    background-position: top center;
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    border: none;

`;
export const PinLaboratorio = styled.button `

    background: url( ${LogoPinLaboratorio} ) no-repeat;
    background-position: top center;
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    border: none;

`;
export const PinMedico = styled.button `

    background: url( ${LogoPinMedico} ) no-repeat;
    background-position: top center;
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    border: none;

`;


export const HeaderMedico = styled.div `

    background: url( ${Bg} ) no-repeat #F5F5F5;
    background-position: top center;

    svg{
        margin: 30px 0 0 15px;
        color: #FFF;
        font-size: 20px;
    }

    img{
        width: 100px;
        height: 100px;
        border-radius: 6px;
        float: left;
    }

    header{
        margin: 20px 0 0 15px;
        height: 110px;    
    }

    h1{
        float: left;
        color: #FFF;
        font-size: 20px;
        margin-left: 15px;
        width: 230px;
        height: 53px;
        align-items: end;
        display: flex;
    }

    p{
        float: left;
        margin-top: -13px;
    }
    p svg{
        font-size: 12px;
        color: #CCC;
    }

`;


export const Medico = styled.div `

    background: #FFF;
    max-width: 375px;
    padding: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    header {
        display: flex;
        flex-direction: row; 
        margin-bottom: 20px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    h2{
        font-size: 16px;
        color: #00B4BE;
    }
    h3{
        font-size: 13px;
        color: #7D7D7D;
        text-decoration: line-through;
    }

    div{
        font-size: 14px;
        padding; 10px 20px;
    }
    div:last-child{
        text-align: right;
    }

    p{
        line-height: 1.8;
        margin-bottom: 10px;
    }

    .medicoSobre{
        height: 200px;
        overflow: auto;
        margin-top: 10px;
    }

`;


export const ButtonAgendar = styled.a `

    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 4px;    
    background: #00B4BE;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin-top: 20px;


    @media (max-width: 600px) {

        margin-top: 10px;

    }

`;


export const Endereco = styled.div `

    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #385170;

    svg{
        font-size: 30px;
        margin-right: 20px;
    }

    p{
        margin-bottom: 0;
    }

    select{
        padding: 10px;
        border-radius: 4px; 
        border: 1px solid #DDD;
        width: 100%;
        font-size: 14px;
    }

`;




export const HeaderAgenda = styled.div `

    background: url( ${Bg} ) no-repeat #F5F5F5;
    background-position: top center;

    svg{
        margin: 0 10px 0 0;
        color: #FFF;
        font-size: 20px;
    }

    h1{
        padding: 20px;
        color: #FFF;
        font-size: 20px;
    }

`;


export const Data = styled.div `

    width: 100%!important;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    a{
        padding: 10px 12px;
        text-align: center;
        color: #7D7D7D;
        background:  #F5F5F5;
    } 
    
    a p{
        margin-top: 10px;
    }


    @media (max-width: 600px) {

        a{
            padding: 10px 6px 10px 7px;
        }

        a p{
            font-size: 11px!important;
        }

    }

`;


export const Horarios = styled.div `

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    a{
        padding: 12px;
        text-align: center;
        border: 1px solid #CCC;
        margin: 2px;
        display: block;
        color: #7D7D7D;
        background:  #F5F5F5;
    }

    .active {
        background:  #CCC;
        color: #385170;
    }

    @media (max-width: 600px) {

        a{
            padding: 7px;
            font-size: 11px;
        }

    }

`;


export const ResumoConsulta = styled.div `

    background: #F5F5F5;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #385170;

    h2{
        font-size: 16px;
        color: #385170;
        margin-bottom: 0!important;
        max-width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    h3{
        font-size: 13px;
        margin-bottom: 0!important;
        max-width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    div:last-child{
        text-align: right;
    }

`;


export const DataHorario = styled.div `

    background: #F5F5F5;
    padding: 20px;
    text-align: center;
    border-top: 1px solid #CCC;
    display: none;

`;


export const HeaderCheckout = styled.div `

    background: url( ${Bg} ) no-repeat #F5F5F5;
    background-position: top center;

    svg{
        margin: 0 10px 0 0;
        color: #FFF;
        font-size: 20px;
    }

    h1{
        padding: 20px;
        color: #FFF;
        font-size: 20px;
    }

`;


export const Checkout = styled.div `

    background: #FFF;
    max-width: 375px;
    padding: 20px;

`;


export const ButtonFinalizar = styled.a `

    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 4px;    
    background: #03AD24;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    display: none;
    margin-top: 20px;

`;

export const MeioDePagamento = styled.div `

    h1{
        font-size: 16px;
        color: #385170;
    }

`;


export const FormaPagamento = styled.div `

    a{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        border: 1px solid #CCC;
        background:  #F5F5F5;
    }

    img {
        width: 30px!important;
        height: auto!important;
        margin-right: 20px;
    }

    h2{
        font-size: 14px!important;
        color: #385170;
        margin: 0!important;
    }

    h3{
        font-size: 12px!important;
        margin: 0!important;
    }

    .active {
        background:  #CCC;
        color: #385170;
    }

`;


export const Confirmacao = styled.div `

    background: #FFF;
    text-align: center;
    padding: 50px;

    svg{
        font-size: 100px;
        margin-bottom: 30px;
        color: #385170;
    }

    h1{
        font-size: 25px;
        color: #385170;
        margin-bottom: 20px;
    }

    p{
        margin-bottom: 50px;
        line-height: 2;
    }

`;


export const MihasConsultas = styled.div `
    
    text-align: left;
    padding: 20px 30px 0 30px;
    margin-bottom: 20px;
    background: #EEE;
    display: none;

    h1{
        font-size: 17px;
        color: #385170;
        text-align: left;
    }

    h2{
        font-size: 15px;
        text-align: left;
    }

    h3{
        font-size: 13px;
        text-align: left;
    }

    a{
        color: #FFF!important;
        font-size: 13px;
        background: #03AD24;
    }

`;