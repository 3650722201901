import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import { Container, Form, SubmitButton } from './styles';
import { FaAngleLeft } from 'react-icons/fa';

class EsqueciMinhaSenha extends Component{

  constructor(props){
    super(props);
    this.state = {
      email: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    let state = this.state;

    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      
      var returnApi = JSON.parse(xhr.response);

      Alert.closeAll();
      if(returnApi[0].status == false){
        Alert.error(returnApi[0].mensagem, { position: 'top', effect: 'slide', timeout: 'none' });
      }
      else{
        Alert.success('Enviamos para seu e-mail uma nova senha.', { position: 'top', effect: 'slide', timeout: 'none' });
      }

    });
    xhr.open('GET', 'https://app.vevee.com.br/apiinterna/esqueciminhasenha?email=' + state.email);
    xhr.send();
  }

  render(){
      return(
        <div id="baseContainer" style={{background: '#CCC', marginTop: '0'}}>
          <Container style={{marginTop: '20px'}}>
            <Link to={'./login'}><FaAngleLeft /></Link>

            <h1>Esqueci Minha Senha</h1>
            
            <Form onSubmit={this.handleSubmit}>
              <input type="text" placeholder="E-mail" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} style={{textTransform: 'lowercase'}} required /><br />

              <SubmitButton>Enviar</SubmitButton>
            </Form>
          </Container>
          <Alert stack={{limit: 3}} />
        </div>
      );
  }

}

export default EsqueciMinhaSenha;