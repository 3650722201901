import styled from 'styled-components';

export const Container = styled.div `

    height: 100%;

    nav{
        height: 100%;
        background: #FFF;
        padding: 30px;
        position: fixed;
    }
    nav ul li{
        list-style: none;
        margin-bottom: 30px;
    }

    nav ul li a{
        color: #333333;
    }

    #col-perfil{
        float: left;
        padding: 10px 20px;
        margin-left: 220px;
    }
    #col-perfil h1{
        color: #333333;
        font-size: 24px;
        margin: 20px 0;
    }
    #col-perfil ul li{
        list-style: none;
        border-bottom: 1px solid #CCC;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    #col-perfil ul li h3{
        color: #333333;
    }
    #col-perfil ul li p{
        margin: 10px 0;
    }
    #col-perfil ul li a{
        margin: 0 10px;
    }

    #listaConsultas{
        background: #FFF;
        padding: 10px;
        width: 500px;
    }

    #col-form{
        float: left;
        padding: 10px 20px;
        background: #FFF;
        width: 350px;
        margin: -11px 0 0 240px;
    }

    #col-form:last-child{
        margin: -11px 0 0 20px;
    }

    .titulo{
        color: #373a3c;
        font-size: 16px!important;
        text-transform: uppercase;
        border-bottom: 1px solid #CCC;
        padding: 10px;
        margin-bottom: 20px
    }


    @media (max-width: 600px) {

        nav{
            display: none;
        }

        #col-perfil{
            padding: 10px 20px;
            margin: 0 10px;
            width: 83%;
        }

        #col-perfil h1{
            margin: 10px 0 0 0;
        }

        #col-form, #col-form:last-child{
            padding: 10px 20px;
            background: #FFF;
            margin: 20px 10px 0 10px;
            width: 83%;
        }

    }

`;

export const Form = styled.form `
    
    input, select{
        flex: 1;
        padding: 15px;
        margin: 10px auto 20px;
        border-radius: 4px; 
        border: 1px solid #DDD;
        width: 90%;
        text-transform: capitalize;
    }

    select{
        width: 98%;
    }

    textarea{
        flex: 1;
        padding: 10px;
        margin: 0 auto 10px;
        border-radius: 4px; 
        border: 1px solid #DDD;
        width: 90%;
        height: 50px;
        font-size: 14px;
        color: #666;
    }

    ul li{
        width: 50%;
        text-align: center;
        float: left;
        list-style: none;
    }

    svg{
        margin: 0 20px 0 0;
        color: #DDD;
        font-size: 30px;
    }

    .col-lef-50{
        width: 48%;
        float: left;
    }
    .col-rig-50{
        width: 48%;
        float: right;
    }

    .col-lef-50 input, .col-rig-50 input {
        width: 78%;
    }

`;

export const SubmitButton = styled.button `

    flex: 1;
    padding: 10px 15px;
    display: table;
    border: none;
    border-radius: 4px;    
    background: #1F2F59;
    color: #FFF;
    width: 88%;
    margin: 5px auto 0;
    text-transform: uppercase;
    margin-bottom: 30px;

`;

export const ConsultaItem = styled.div `

    svg{
        color: #90D2CD;
    }

    h1{
        font-size: 17px!important;
        margin: 5px 0!important;
    }
    h2{
        font-size: 14px!important;
        color: #333333;
    }

    p{
        color: #7D7D7D;
        font-size: 13px;
    }

    img{
        width: 80px;
        height: 80px;
        float: left;
        margin-right: 10px;
    }
    
    div{
        border: 2px solid #999999;
        border-radius: 5px;
        font-size: 13px;
        float: right;
        
    }
    div p:first-child{
        background: #FBFBFB;
        padding: 5px 20px;
        margin: 0!important;
        font-size: 17px;
    }
    div p:last-child{
        background: #81A74D;
        padding: 5px 20px;
        margin: 0!important;
        font-size: 16px;
        color: #FFF;
    }


    @media (max-width: 600px) {

        div{
            border: none;
            float: none;
        }
        div p:first-child{
            background: none;
            float: left;
        }
        div p:last-child{
            background: none;
            color: #000;
        }

    }

`;

export const ButtonAvaliar = styled.button `

    flex: 1;
    padding: 10px 15px;
    display: table;
    border: none;
    border-radius: 4px;    
    background: #1F2F59;
    color: #FFF;
    width: 88%;
    text-transform: uppercase;
    margin: 20px auto 5px;
    display: none;

`;

export const Detalhes = styled.div `

    background: #FFF;
    max-width: 375px;
    padding: 20px;

    svg{
        margin: 0 0 20px 0;
        color: #13365C;
        font-size: 30px;
    }

    h1{
        color: #13365C;
        font-size: 16px;
        margin-bottom: 10px;
    }
    
    h2{
        font-size: 16px;
        border-bottom: 1px dotted #CCC;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    h3{
        font-size: 16px;
        color: #7D7D7D;
        margin-top: 25px;
    }

    p{
        color: #7D7D7D;
        font-size: 13px;
        margin-top: 3px;
    }
    p:last-child{
        margin-top: 10px;
    }

    img{
        width: 80px;
        height: 80px;
        border-radius: 6px;
        float: left;
        margin-right: 10px;
    }
    button{
        border: none;
    }

`;