import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import NavPerfil from '../../components/NavPerfil';

import { FaCreditCard, FaCalendar, FaUsers, FaUser, FaTimes, FaIdCard } from 'react-icons/fa';

import { Container, Form, SubmitButton } from './styles';

class MeusCartoes extends Component{

  constructor(props){
    super(props);
    this.state = {
      uid: '',
      nome: '',
      cpf: '',
      celular: '',
      email: '',
      dataNascimento: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      cep: '',
      zoopIdCartao: '',
      cartaoNome: '',
      cartaoNumero: '',
      cartaoData: '',
      cartaoCvv: '',
      cartoes: []
    };

    if(localStorage.getItem('@vevee/usuariocodigo')){
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', () => {
        let state = this.state;
        var returnApi = JSON.parse(xhr.response);

        state.nome = returnApi[0].nome;
        state.email = returnApi[0].email;
        state.cpf = returnApi[0].cpf;
        state.celular = returnApi[0].celular;
        state.dataNascimento = returnApi[0].dataNascimento;
        state.endereco = returnApi[0].endereco;
        state.numero = returnApi[0].numero;
        state.complemento = returnApi[0].complemento;
        state.bairro = returnApi[0].bairro;
        state.cidade = returnApi[0].cidade;
        state.estado = returnApi[0].estado;
        state.cep = returnApi[0].cep;
        this.setState(state);
  
      });
      xhr.open('GET', 'https://app.vevee.com.br/apiinterna/perfil?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhr.send();


      var xhr2 = new XMLHttpRequest();
      xhr2.addEventListener('load', () => {
        let state = this.state;
        var returnApi2 = JSON.parse(xhr2.response);

        if(returnApi2 != null){
          for(var i = 0; i < returnApi2.length; i++){ 
            state.cartoes.push({
              key: returnApi2[i].id,
              nome: returnApi2[i].nome,
              numero: returnApi2[i].numero,
              bandeira: returnApi2[i].bandeira
            })
          }
        }
        this.setState(state);

      });
      xhr2.open('GET', 'https://app.vevee.com.br/apiinterna/cartoes?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhr2.send();
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {

    document.getElementById('botaoSalvar').innerText = 'Aguarde, processando...';

    event.preventDefault();
    let state = this.state;
/*
    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      let state = this.state;
      var returnApi = JSON.parse(xhr.response);

      Alert.closeAll();
      if(returnApi[0].status == false){
        Alert.error(returnApi[0].mensagem, { position: 'top', effect: 'slide', timeout: 'none' });
      }
      else{
        localStorage.setItem('@vevee/usuarionome', state.nome);
        window.location.href = "/meuscartoes";
      }
    });
    xhr.open('GET', 'https://app.vevee.com.br/apiinterna/cartoes?acao=insert&codigo=' + localStorage.getItem('@vevee/usuariocodigo') + '&nome=' + state.nome + '&email=' + state.email + '&celular=' + state.celular + '&cpf=' + state.cpf + '&datanascimento=' + state.dataNascimento + '&endereco=' + state.endereco + '&numero=' + state.numero + '&complemento=' + state.complemento + '&bairro=' + state.bairro + '&cidade=' + state.cidade + '&estado=' + state.estado + '&cep=' + state.cep + '&cartaonome=' + state.cartaoNome + '&cartaonumero=' + state.cartaoNumero + '&cartaodata=' + state.cartaoData + '&cartaocvv=' + state.cartaoCvv);
    xhr.send();
*/

    if(state.zoopIdComprador == undefined || state.zoopIdComprador == ''){
        var xhr = new XMLHttpRequest();
        xhr.addEventListener('load', () => {
          state.zoopIdComprador = xhr.responseText;
          this.setState(state);
          
          var xhr2 = new XMLHttpRequest();
          xhr2.addEventListener('load', () => {
              state.zoopIdCartao = xhr2.responseText;
              this.setState(state);
              
              Alert.closeAll();
              if(xhr2.responseText == 'error'){
                Alert.error('Dados inválidos, tente novamente.', { position: 'top', effect: 'slide', timeout: 'none' });
              }
              else{
                var xhrPerfil = new XMLHttpRequest();
                xhrPerfil.open('GET', 'https://app.vevee.com.br/apiinterna/perfil?codigo=' + localStorage.getItem('@vevee/usuariocodigo') + '&zoopIdComprador=' + state.zoopIdComprador);
                xhrPerfil.send();
  
                var xhrCartao = new XMLHttpRequest();
                xhrCartao.open('GET', 'https://app.vevee.com.br/apiinterna/cartoes?acao=insert&codigo=' + localStorage.getItem('@vevee/usuariocodigo') + '&nome=' + state.cartaoNome + '&numero=' + state.cartaoNumero);
                xhrCartao.send();
  
                var xhrAtualiza = new XMLHttpRequest();
                xhrAtualiza.addEventListener('load', () => {
                  var returnApiAtualiza = JSON.parse(xhrAtualiza.response);

                  if(returnApiAtualiza != null){
                    for(var i = 0; i < returnApiAtualiza.length; i++){ 
                      state.cartoes.push({
                        key: returnApiAtualiza[i].id,
                        nome: returnApiAtualiza[i].nome,
                        numero: returnApiAtualiza[i].numero,
                        bandeira: returnApiAtualiza[i].bandeira
                      })
                    }
                  }
                  state.cartaoCvv = '';
                  state.cartaoData = '';
                  state.cartaoNome = '';
                  state.cartaoNumero = '';

                  this.setState(state);
                  Alert.success('Cartão adicionado.', { position: 'top', effect: 'slide', timeout: 'none' });
                });
                xhrAtualiza.open('GET', 'https://app.vevee.com.br/apiinterna/cartoes?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
                xhrAtualiza.send();
              }
          });
          xhr2.open('GET', 'https://app.vevee.com.br/pagamento/cartaonovo?customer=' + state.zoopIdComprador + '&nome=' + state.cartaoNome + '&numero=' + state.cartaoNumero + '&data=' + state.cartaoData + '&cvv=' + state.cartaoCvv);
          xhr2.send();
        });
        xhr.open('GET', 'https://app.vevee.com.br/pagamento/compradornovo?nome=' + state.nome + '&cpf=' + state.cpf + '&endereco=' + state.endereco + '&numero=' + state.numero + '&complemento=' + state.complemento + '&bairro=' + state.bairro + '&cidade=' + state.cidade + '&estado=' + state.estado + '&cep=' + state.cep);
        xhr.send();
    }

  }

  render(){
    let state = this.state;

    return(
      <>
        <NavPerfil />

        <div id="baseContainer">
          <Container>

            <nav>
              <ul>
                <li><Link to={'./minhasconsultas'}><FaCalendar /> Consultas</Link></li>
                <li><Link to={'./meusdependentes'}><FaUsers /> Dependentes</Link></li>
                <li><Link to={'./meuscartoes'}><FaCreditCard /> Meios de Pagamento</Link></li>
                <li><Link to={'./minhacarteirinha'}><FaIdCard /> Carteirinha</Link></li>
                <li><Link to={'./meuperfil'}><FaUser /> Perfil</Link></li>
                <li style={{borderTop: '1px solid #CCC', marginTop: '10px', paddingTop: '20px'}}><Link to={'./login'}><FaTimes /> Sair</Link></li>
              </ul>
            </nav>

            <div id="col-perfil">
              <h1>Meus Cartões</h1>
            </div>
            <br clear="all" />

            <div id="col-form">
                <h1 className="titulo" style={{marginTop: '0'}}>Cartões vinculados</h1>
                <ul>
                  {state.cartoes.map((item) => {
                    return(
                      <li>
                        <h3>{item.nome}</h3>
                        <p>{item.numero}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>

            <div id="col-form">
                <Form onSubmit={this.handleSubmit}>
                  <h1 className="titulo">Adicionar Meio de Pagamento</h1>
                  
                  <div>
                    <label>Nome no cartão</label>
                    <InputMask type="text" placeholder="Nome no cartão" value={this.state.cartaoNome} onChange={(e) => this.setState({cartaoNome: e.target.value})} style={{ textTransform: 'uppercase' }} required />
                  </div>

                  <div>
                    <label>Número do cartão</label>
                    <InputMask type="text" placeholder="Número do cartão" value={this.state.cartaoNumero} onChange={(e) => this.setState({cartaoNumero: e.target.value})} required mask="9999 9999 9999 9999" /><br />
                  </div>

                  <div className="col-lef-50">
                    <label>Data de Validade</label>
                    <InputMask type="text" placeholder="Data de Validade" value={this.state.cartaoData} onChange={(e) => this.setState({cartaoData: e.target.value})} required mask="99/99" />
                  </div>
                  <div className="col-rig-50">
                    <label>CCV</label>
                    <InputMask type="text" placeholder="Código de segurança" value={this.state.cartaoCvv} onChange={(e) => this.setState({cartaoCvv: e.target.value})} required mask="999" />
                  </div>

                  <br clear="all" />
                  <h1 className="titulo">Endereço</h1>
                  <div>
                    <label>Endereço</label>
                    <InputMask type="text" placeholder="Endereço" value={this.state.endereco} onChange={(e) => this.setState({endereco: e.target.value})} required /><br />
                  </div>

                  <div className="col-lef-50">
                    <label>Número</label>
                    <InputMask type="text" placeholder="Número" value={this.state.numero} onChange={(e) => this.setState({numero: e.target.value})} required />
                  </div>
                  <div className="col-rig-50">
                    <label>Complemento</label>
                    <InputMask type="text" placeholder="Complemento" value={this.state.complemento} onChange={(e) => this.setState({complemento: e.target.value})} />
                  </div>

                  <div className="col-lef-50">
                    <label>Bairro</label>
                    <InputMask type="text" placeholder="Bairro" value={this.state.bairro} onChange={(e) => this.setState({bairro: e.target.value})} required />
                  </div>
                  <div className="col-rig-50">
                    <label>CEP</label>
                    <InputMask type="text" placeholder="CEP" value={this.state.cep} onChange={(e) => this.setState({cep: e.target.value})} required mask="99999-999" />
                  </div>

                  <div className="col-lef-50">
                    <label>Cidade</label>
                    <InputMask type="text" placeholder="Cidade" value={this.state.cidade} onChange={(e) => this.setState({cidade: e.target.value})} required />
                  </div>
                  <div className="col-rig-50">
                    <label>Estado</label>
                    <InputMask type="text" placeholder="Estado" value={this.state.estado} onChange={(e) => this.setState({estado: e.target.value})} required style={{ textTransform: 'uppercase' }} maxLength="2" />
                  </div>

                  
                  <br clear="all" />
                  <SubmitButton id="botaoSalvar">Salvar</SubmitButton><br /><br /><br />
              </Form> 
            </div>
          </Container>
          <Alert stack={{limit: 3}} />
        </div>
      </>
    );
  }

}

export default MeusCartoes;