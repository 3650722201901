import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import NavPerfil from '../../components/NavPerfil';

import { FaCreditCard, FaCalendar, FaUsers, FaUser, FaTimes, FaIdCard } from 'react-icons/fa';

import { Container, Form, SubmitButton } from './styles';

class MeusDependentes extends Component{

  constructor(props){
    super(props);
    this.state = {
      key: '',
      nome: '',
      cpf: '',
      dataNascimento: '',
      celular: '',
      tipo: '',
      dependentes: [],
      planoNome: '',
      planoNumero: '',
      planoData: ''
    };

    if(localStorage.getItem('@vevee/usuariocodigo')){
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', () => {
        let state = this.state;
        var returnApi = JSON.parse(xhr.response);

        if(returnApi != null){
          for(var i = 0; i < returnApi.length; i++){ 
            state.dependentes.push({
              key: returnApi[i].id,
              nome: returnApi[i].nome,
              tipo: returnApi[i].tipo
            })
          }
        }
        this.setState(state);

      });
      xhr.open('GET', 'https://app.vevee.com.br/apiinterna/dependentes?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhr.send();
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.editar = this.editar.bind(this);
    this.excluir = this.excluir.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    let state = this.state;

    if(state.key){
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', () => {
        
        var returnApi = JSON.parse(xhr.response);
        
        Alert.closeAll();
        if(returnApi[0].status == false){
          Alert.error(returnApi[0].mensagem, { position: 'top', effect: 'slide', timeout: 'none' });
        }
        else{
          Alert.success('Dependente atualizado.', { position: 'top', effect: 'slide', timeout: 'none' });
        }

      });
      xhr.open('GET', 'https://app.vevee.com.br/apiinterna/dependentes?acao=update&codigo=' + localStorage.getItem('@vevee/usuariocodigo') + '&id=' + state.key + '&nome=' + state.nome + '&cpf=' + state.cpf + '&celular=' + state.celular + '&dataNascimento=' + state.dataNascimento + '&tipo=' + state.tipo + '&planoNome=' + state.planoNome + '&planoNumero=' + state.planoNumero + '&planoData=' + state.planoData);
      xhr.send();
    }
    else{
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', () => {
        
        var returnApi = JSON.parse(xhr.response);
        
        Alert.closeAll();
        if(returnApi[0].status == false){
          Alert.error(returnApi[0].mensagem, { position: 'top', effect: 'slide', timeout: 'none' });
        }
        else{
          Alert.success('Dependente cadastrado(a).', { position: 'top', effect: 'slide', timeout: 'none' });
        }

      });
      xhr.open('GET', 'https://app.vevee.com.br/apiinterna/dependentes?acao=insert&codigo=' + localStorage.getItem('@vevee/usuariocodigo') + '&nome=' + state.nome + '&cpf=' + state.cpf + '&celular=' + state.celular + '&dataNascimento=' + state.dataNascimento + '&tipo=' + state.tipo + '&planoNome=' + state.planoNome + '&planoNumero=' + state.planoNumero + '&planoData=' + state.planoData);
      xhr.send();
    }

    var xhr2 = new XMLHttpRequest();
    xhr2.addEventListener('load', () => {
        var returnApi2 = JSON.parse(xhr2.response);
        state.dependentes = [];

        if(returnApi2 != null){
          for(var i = 0; i < returnApi2.length; i++){ 
            state.dependentes.push({
              key: returnApi2[i].id,
              nome: returnApi2[i].nome,
              tipo: returnApi2[i].tipo
            })
          }
        }
        this.setState(state);
        console.log('chegou aqui');
      });
      xhr2.open('GET', 'https://app.vevee.com.br/apiinterna/dependentes?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhr2.send();
      
    
  }


  editar(key){
    let state = this.state;

    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      var returnApi = JSON.parse(xhr.response);

      state.key = returnApi[0].id;
      state.nome = returnApi[0].nome;
      state.cpf = returnApi[0].cpf;
      state.dataNascimento = returnApi[0].dataNascimento;
      state.celular = returnApi[0].celular;
      state.tipo = returnApi[0].tipo;
      state.planoNome = returnApi[0].planoNome;
      state.planoNumero = returnApi[0].planoNumero;
      state.planoData = returnApi[0].planoData;

      this.setState(state);

    });
    xhr.open('GET', 'https://app.vevee.com.br/apiinterna/dependentes?acao=get&codigo=' + localStorage.getItem('@vevee/usuariocodigo') + '&id=' + key);
    xhr.send();   
  }

  excluir(key){
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://app.vevee.com.br/apiinterna/dependentes?acao=remove&codigo=' + localStorage.getItem('@vevee/usuariocodigo') + '&id=' + key);
    xhr.send();

    Alert.closeAll();   
    Alert.success('Dependente removido.', { position: 'top', effect: 'slide', timeout: 'none' });

    if(localStorage.getItem('@vevee/usuariocodigo')){
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', () => {
        let state = this.state;
        var returnApi = JSON.parse(xhr.response);
        state.dependentes = [];
        if(returnApi != null){
          for(var i = 0; i < returnApi.length; i++){ 
            state.dependentes.push({
              key: returnApi[i].id,
              nome: returnApi[i].nome,
              tipo: returnApi[i].tipo
            })
          }
        }
        this.setState(state);

      });
      xhr.open('GET', 'https://app.vevee.com.br/apiinterna/dependentes?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhr.send();
    }
  }

  render(){
    let state = this.state;

    return(
      <>
        <NavPerfil />

        <div id="baseContainer">
          <Container>

            <nav>
              <ul>
                <li><Link to={'./minhasconsultas'}><FaCalendar /> Consultas</Link></li>
                <li><Link to={'./meusdependentes'}><FaUsers /> Dependentes</Link></li>
                <li><Link to={'./meuscartoes'}><FaCreditCard /> Meios de Pagamento</Link></li>
                <li><Link to={'./minhacarteirinha'}><FaIdCard /> Carteirinha</Link></li>
                <li><Link to={'./meuperfil'}><FaUser /> Perfil</Link></li>
                <li style={{borderTop: '1px solid #CCC', marginTop: '10px', paddingTop: '20px'}}><Link to={'./login'}><FaTimes /> Sair</Link></li>
              </ul>
            </nav>

            <div id="col-perfil">
              <h1>Dependentes</h1>
            </div>
            <br clear="all" />

            <div id="col-form">
                <h1 className="titulo" style={{marginTop: '0'}}>Dependentes vinculados</h1>
                <ul>
                  {state.dependentes.map((item) => {
                    return(
                      <li>
                        <h3>{item.nome}</h3>
                        <p>{item.tipo}</p>
                        <div style={{textAlign: 'center'}}><a href="#" onClick={() => { this.editar(item.key); }}>Editar</a> | <a href="#" onClick={() => { this.excluir(item.key); }}>Remover</a></div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            
            <div id="col-form">
                <Form onSubmit={this.handleSubmit}>
                  <h1 className="titulo">Adicionar um Dependente</h1>
                  <div>
                    <label>Nome Completo</label>
                    <InputMask type="text" placeholder="Nome" value={this.state.nome} onChange={(e) => this.setState({nome: e.target.value})} required /><br />
                  </div>

                  <div className="col-lef-50">
                    <label>CPF</label>
                    <InputMask type="text" placeholder="CPF" value={this.state.cpf} onChange={(e) => this.setState({cpf: e.target.value})} required mask="999.999.999-99" />
                  </div>
                  <div className="col-rig-50">
                    <label>Data de Nascimento</label>
                    <InputMask type="text" placeholder="Data de Nascimento" value={this.state.dataNascimento} onChange={(e) => this.setState({dataNascimento: e.target.value})} required mask="99/99/9999" />
                  </div>

                  <div className="col-lef-50">
                    <label>Celular</label>
                    <InputMask type="text" placeholder="Celular" value={this.state.celular} onChange={(e) => this.setState({celular: e.target.value})} required mask="(99) 99999-9999" />
                  </div>
                  <div className="col-rig-50">
                    <label>Grau de parentensco</label>
                    <select name="tipo" value={this.state.tipo} onChange={(e) => this.setState({tipo: e.target.value})} required>
                        <option value="">Escolha</option>
                        <option value="Filho(a)">Filho(a)</option>
                        <option value="Pai">Pai</option>
                        <option value="Mãe">Mãe</option>
                        <option value="Irmão(a)">Irmão(a)</option>
                        <option value="Esposo(a)">Esposo(a)</option>
                        <option value="Namorado(a)">Namorado(a)</option>
                        <option value="Outros">Outros</option>
                    </select>
                  </div>

                  <h1 className="titulo">Plano de Saúde</h1>
                  <div>
                    <label>Plano</label>
                    <InputMask type="text" placeholder="Plano" value={this.state.planoNome} onChange={(e) => this.setState({planoNome: e.target.value})} /><br />
                  </div>

                  <div className="col-lef-50">
                    <label>Núm. Carteira</label>
                    <InputMask type="text" placeholder="Núm. Carteira" value={this.state.planoNumero} onChange={(e) => this.setState({planoNumero: e.target.value})} />
                  </div>
                  <div className="col-rig-50">
                    <label>Data de Validade</label>
                    <InputMask type="text" placeholder="Data de Validade" value={this.state.planoData} onChange={(e) => this.setState({planoData: e.target.value})} mask="99/99/9999" />
                  </div>

                  <SubmitButton>Salvar</SubmitButton><br /><br /><br />
              </Form> 
            </div>
          </Container>
          <Alert stack={{limit: 3}} />
        </div>
      </>
    );
  }

}

export default MeusDependentes;