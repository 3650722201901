import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

let firebaseConfig = {
    apiKey: "AIzaSyAEqBdO-B7wRV44hL7Wyxn8hQ-0Nkt7w68",
    authDomain: "vidasapp-d9d6d.firebaseapp.com",
    databaseURL: "https://vidasapp-d9d6d.firebaseio.com",
    projectId: "vidasapp-d9d6d",
    storageBucket: "vidasapp-d9d6d.appspot.com",
    messagingSenderId: "343033103868",
    appId: "1:343033103868:web:33cb1445b47945f63e9e79",
    measurementId: "G-X98LP0QZJM"
};
firebase.initializeApp(firebaseConfig);

export default firebase;