import styled from 'styled-components';

export const Container = styled.div `

    height: 100%;

    nav{
        height: 100%;
        background: #FFF;
        padding: 30px;
        position: fixed;
    }
    nav ul li{
        list-style: none;
        margin-bottom: 30px;
    }

    nav ul li a{
        color: #333333;
    }

    #col-perfil{
        float: left;
        padding: 10px 20px;
        margin-left: 220px;
    }
    #col-perfil h1{
        color: #333333;
        font-size: 24px;
        margin: 20px 0;
    }

    #col-perfil img{
        width: 185px;
    }
    #col-perfil a{
        color: #333333;
    }

    #col-form{
        float: left;
        padding: 10px 20px;
        background: #FFF;
        width: 350px;
        margin: -11px 0 0 240px;
    }

    #col-form:last-child{
        margin: -11px 0 0 20px;
    }

    .titulo{
        color: #373a3c;
        font-size: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #CCC;
        padding: 10px;
        margin-bottom: 20px
    }


    @media (max-width: 600px) {

        nav{
            display: none;
        }

        #col-perfil{
            padding: 10px 20px;
            margin: 0 10px;
            width: 83%;
        }

        #col-perfil h1{
            margin: 10px 0 0 0;
        }

        #col-form, #col-form:last-child{
            padding: 10px 20px;
            background: #FFF;
            margin: 20px 10px 0 10px;
            width: 83%;
        }

    }

`;

export const Form = styled.form `
        
    input{
        flex: 1;
        padding: 15px;
        margin: 10px auto 20px;
        border-radius: 4px; 
        border: 1px solid #DDD;
        width: 90%;
        text-transform: capitalize;
    }

    .col-lef-50{
        width: 45%;
        float: left;
    }
    .col-rig-50{
        width: 45%;
        float: right;
    }

    .col-lef-50 input, .col-rig-50 input {
        width: 78%;
    }

`;

export const SubmitButton = styled.button `

    flex: 1;
    padding: 10px 15px;
    display: table;
    border: none;
    border-radius: 4px;    
    background: #1F2F59;
    color: #FFF;
    width: 88%;
    margin: 5px auto 0;
    text-transform: uppercase;
    margin-bottom: 30px;

`;