import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import NavPerfil from '../../components/NavPerfil';

import { FaCreditCard, FaCalendar, FaUsers, FaUser, FaStar, FaTimes, FaMapMarkerAlt, FaIdCard } from 'react-icons/fa';

import { Container, Form, ConsultaItem, ButtonAvaliar, Detalhes } from './styles';

class MinhasConsultas extends Component{

  constructor(props){
    super(props);
    this.state = {
      uid: '',
      key: '',
      consultas: [],
      consultaDetalhes: {},
      compareceu: '',
      atendidoHorario: '',
      mensagem: '',
      nota: ''
    };
    this.pageDetalhes = this.pageDetalhes.bind(this);
    this.fechar = this.fechar.bind(this);
    this.salvar = this.salvar.bind(this);
    this.alteraNota = this.alteraNota.bind(this);

    if(localStorage.getItem('@vevee/usuariocodigo')){
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', () => {
        let state = this.state;
        var returnApi = JSON.parse(xhr.response);
        state.consultas = [];

        if(returnApi != null){
          for(var i = 0; i < returnApi.length; i++){ 
            state.consultas.push({
              key: returnApi[i].key,
              consultaPara: returnApi[i].consultaPara,
              data: returnApi[i].data,
              horario: returnApi[i].horario,
              profissionalNome: returnApi[i].profissionalNome,
              profissionalLogo: returnApi[i].profissionalLogo,
              profissionalEspecialidade: returnApi[i].profissionalEspecialidade,
              profissionalEndereco: returnApi[i].profissionalEndereco,
              profissionalBairro: returnApi[i].profissionalBairro,
              profissionalCidade: returnApi[i].profissionalCidade
            })
          }
        }
        this.setState(state);

      });
      xhr.open('GET', 'https://app.vevee.com.br/apiinterna/minhasconsultas?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhr.send();
    }

/*
    firebase.auth().onAuthStateChanged((user)=>{
      let state = this.state;
      firebase.database().ref('usuarios/' + user.uid).on('value', (snapshot) => {
        state.uid = user.uid;

        firebase.database().ref('consultas').orderByChild('usuarioId').equalTo(user.uid).on('value', (snapshot) => {
          state.consultas = [];
          snapshot.forEach((childItem) => {
            state.consultas.push({
              key: childItem.key,
              consultaPara: childItem.val().consultaPara,
              data: childItem.val().data,
              horario: childItem.val().horario,
              profissionalNome: childItem.val().profissionalNome,
              profissionalLogo: childItem.val().profissionalLogo,
              profissionalEspecialidade: childItem.val().profissionalEspecialidade,
              profissionalEndereco: childItem.val().profissionalEndereco,
              profissionalBairro: childItem.val().profissionalBairro,
              profissionalCidade: childItem.val().profissionalCidade
            })
          });
          this.setState(state);
        });
      });
    });
*/
  }

  pageDetalhes(key) {
    let state = this.state;

/*
    firebase.database().ref('consultas/' + key).on('value', (snapshot) => {
      state.consultaDetalhes = snapshot.val();
      state.key = key;
      this.setState(state);  
    });

    firebase.database().ref('consultas-avaliacao/' + key).on('value', (snapshot) => {
      if(snapshot.val()){
        state.compareceu = snapshot.val().compareceu;
        state.atendidoHorario = snapshot.val().atendidoHorario;
        state.mensagem = snapshot.val().mensagem;
        state.nota = snapshot.val().nota;
        this.setState(state);

        if(state.compareceu == 'Sim'){
          document.getElementById('compareceu1').checked = true;
        }
        else{
          document.getElementById('compareceu2').checked = true;
        }
    
        if(state.atendidoHorario == 'Sim'){
          document.getElementById('atendidoHorari1').checked = true;
        }
        else{
          document.getElementById('atendidoHorari2').checked = true;
        }
    
        switch (state.nota) {
          case 1:
            document.getElementById('estrela1').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela2').style.setProperty('color', '#DDD');
            document.getElementById('estrela3').style.setProperty('color', '#DDD');
            document.getElementById('estrela4').style.setProperty('color', '#DDD');
            document.getElementById('estrela5').style.setProperty('color', '#DDD');
          break;
          case 2:
            document.getElementById('estrela1').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela2').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela3').style.setProperty('color', '#DDD');
            document.getElementById('estrela4').style.setProperty('color', '#DDD');
            document.getElementById('estrela5').style.setProperty('color', '#DDD');
          break;
          case 3:
            document.getElementById('estrela1').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela2').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela3').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela4').style.setProperty('color', '#DDD');
            document.getElementById('estrela5').style.setProperty('color', '#DDD');
          break;
          case 4:
            document.getElementById('estrela1').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela2').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela3').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela4').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela5').style.setProperty('color', '#DDD');
          break;
          case 5:
            document.getElementById('estrela1').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela2').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela3').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela4').style.setProperty('color', '#FFBB39');
            document.getElementById('estrela5').style.setProperty('color', '#FFBB39');
          break;
          default:
            document.getElementById('estrela1').style.setProperty('color', '#DDD');
            document.getElementById('estrela2').style.setProperty('color', '#DDD');
            document.getElementById('estrela3').style.setProperty('color', '#DDD');
            document.getElementById('estrela4').style.setProperty('color', '#DDD');
            document.getElementById('estrela5').style.setProperty('color', '#DDD');
          break;
        }
      }
      else{
        state.compareceu = '';
        state.atendidoHorario = '';
        state.mensagem = '';
        state.nota = '';
        this.setState(state);
        document.getElementById('compareceu1').checked = false;
        document.getElementById('compareceu2').checked = false;
        document.getElementById('atendidoHorari1').checked = false;
        document.getElementById('atendidoHorari2').checked = false;
        document.getElementById('estrela1').style.setProperty('color', '#DDD');
        document.getElementById('estrela2').style.setProperty('color', '#DDD');
        document.getElementById('estrela3').style.setProperty('color', '#DDD');
        document.getElementById('estrela4').style.setProperty('color', '#DDD');
        document.getElementById('estrela5').style.setProperty('color', '#DDD');
      }
    });
*/
    document.getElementById('minhaConsultaDetalhesContainer').style.setProperty('display', 'block');
  }

  fechar() {
    document.getElementById('minhaConsultaDetalhesContainer').style.setProperty('display', 'none');
  }

  salvar() {
    let state = this.state;
/*
    if(state.compareceu && state.atendidoHorario && state.nota){
      let consultas = firebase.database().ref('consultas-avaliacao');
    
      consultas.child(state.key).set({
        compareceu: state.compareceu,
        atendidoHorario: state.atendidoHorario,
        mensagem: state.mensagem,
        nota: state.nota
      });

      state.compareceu = '';
      state.atendidoHorario = '';
      state.mensagem = '';
      state.nota = '';
      this.setState(state);

      Alert.success('Avaliação salva.', { position: 'top', effect: 'slide', timeout: 'none' });
      document.getElementById('minhaConsultaDetalhesContainer').style.setProperty('display', 'none');
    }
    else{
      Alert.error('Preencha todas as informações.', { position: 'top', effect: 'slide', timeout: 'none' });
    }  
*/     
  }

  alteraNota(nota) {
    let state = this.state;
    state.nota = nota;
    this.setState(state); 

    switch (nota) {
      case 1:
        document.getElementById('estrela1').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela2').style.setProperty('color', '#DDD');
        document.getElementById('estrela3').style.setProperty('color', '#DDD');
        document.getElementById('estrela4').style.setProperty('color', '#DDD');
        document.getElementById('estrela5').style.setProperty('color', '#DDD');
      break;
      case 2:
        document.getElementById('estrela1').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela2').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela3').style.setProperty('color', '#DDD');
        document.getElementById('estrela4').style.setProperty('color', '#DDD');
        document.getElementById('estrela5').style.setProperty('color', '#DDD');
      break;
      case 3:
        document.getElementById('estrela1').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela2').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela3').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela4').style.setProperty('color', '#DDD');
        document.getElementById('estrela5').style.setProperty('color', '#DDD');
      break;
      case 4:
        document.getElementById('estrela1').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela2').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela3').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela4').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela5').style.setProperty('color', '#DDD');
      break;
      case 5:
        document.getElementById('estrela1').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela2').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela3').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela4').style.setProperty('color', '#FFBB39');
        document.getElementById('estrela5').style.setProperty('color', '#FFBB39');
      break;
    }
  }

  render(){
    let state = this.state;

    return(
      <>
        <NavPerfil />

        <div id="baseContainer">
          <Container>

            <nav>
              <ul>
                <li><Link to={'./minhasconsultas'}><FaCalendar /> Consultas</Link></li>
                <li><Link to={'./meusdependentes'}><FaUsers /> Dependentes</Link></li>
                <li><Link to={'./meuscartoes'}><FaCreditCard /> Meios de Pagamento</Link></li>
                <li><Link to={'./minhacarteirinha'}><FaIdCard /> Carteirinha</Link></li>
                <li><Link to={'./meuperfil'}><FaUser /> Perfil</Link></li>
                <li style={{borderTop: '1px solid #CCC', marginTop: '10px', paddingTop: '20px'}}><Link to={'./login'}><FaTimes /> Sair</Link></li>
              </ul>
            </nav>

            <div id="col-perfil">
              <h1>Minhas Consultas</h1>
            </div>
            <br clear="all" />

            <div id="col-form">
                <h1 className="titulo" style={{marginTop: '0'}}>Lista de consultas</h1>
                <ul>
                  {state.consultas.map((item) => {
                    return(
                      <li>
                        <ConsultaItem key={item.key}>
                          <img src={item.profissionalLogo} />
                          <h1>{item.profissionalNome}<div><p>{item.data}</p><p>{item.horario}</p></div></h1>
                          <h2>{item.profissionalEspecialidade}</h2>
                          <p>{item.consultaPara}</p>
                          <br clear="all" />
                          <p><FaMapMarkerAlt /> {item.profissionalEndereco}, {item.profissionalBairro} - {item.profissionalCidade}</p>
                          <ButtonAvaliar onClick={() => this.pageDetalhes(item.key)} key={item.key}>Avaliar atendimento</ButtonAvaliar>
                        </ConsultaItem>
                      </li>
                    );
                  })}
                </ul>
            </div>
            
            <div id="minhaConsultaDetalhesContainer">
              <div id="col-form">
                <h1 className="titulo">Sua avaliação para a consulta</h1>

                <Detalhes>
                  <Form>
                    <h2>Você compareceu a consulta?</h2>
                    <ul>
                      <li><input type="radio" value='Sim' name="compareceu" id="compareceu1" onChange={(e) => this.setState({compareceu: e.target.value})} required />Sim</li>
                      <li><input type="radio" value='Não' name="compareceu" id="compareceu2" onChange={(e) => this.setState({compareceu: e.target.value})} required />Não</li>
                    </ul>
                    <br clear="all" />

                    <h2>Você foi atendido no horário agendado?</h2>
                    <ul>
                      <li><input type="radio" value='Sim' name="atendidoHorario" id="atendidoHorari1" onChange={(e) => this.setState({atendidoHorario: e.target.value})} required />Sim</li>
                      <li><input type="radio" value='Não' name="atendidoHorario" id="atendidoHorari2" onChange={(e) => this.setState({atendidoHorario: e.target.value})} required />Não</li>
                    </ul>
                    <br clear="all" />

                    <h2>Gostaria de deixar fazer alguma crítica, sugestão ou elogios?</h2>
                    <textarea value={this.state.mensagem} onChange={(e) => this.setState({mensagem: e.target.value})} required ></textarea>

                    <br clear="all" />
                    <h2>Qual a nota você atribuiria ao atendimento?</h2>
                    <p>
                      <a onClick={() => { this.alteraNota(1); }}><FaStar id="estrela1" /></a>
                      <a onClick={() => { this.alteraNota(2); }}><FaStar id="estrela2" /></a>
                      <a onClick={() => { this.alteraNota(3); }}><FaStar id="estrela3" /></a>
                      <a onClick={() => { this.alteraNota(4); }}><FaStar id="estrela4" /></a>
                      <a onClick={() => { this.alteraNota(5); }}><FaStar id="estrela5" /></a>
                    </p>

                    <br clear="all" />
                    <ButtonAvaliar onClick={this.salvar}>Enviar</ButtonAvaliar><br /><br /><br />
                  </Form>
                </Detalhes> 
              </div>
            </div>
          </Container>
          <Alert stack={{limit: 3}} />
        </div>
      </>
    );
  }

}

export default MinhasConsultas;