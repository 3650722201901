import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import { Container, Form, SubmitButton } from './styles';

import Logo from '../../images/logo-vevee-color.svg';

class Login extends Component{

  constructor(props){
    super(props);
    this.state = {
      email: '',
      senha: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);

    localStorage.removeItem('@vevee/usuariocodigo');
    localStorage.removeItem('@vevee/usuarionome');
  }

  handleSubmit(event) {
    event.preventDefault();
    let state = this.state;

    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      
      var returnApi = JSON.parse(xhr.response);

      Alert.closeAll();
      if(returnApi[0].status == false){
        Alert.error(returnApi[0].mensagem, { position: 'top', effect: 'slide', timeout: 'none' });
      }
      else{
        localStorage.setItem('@vevee/usuariocodigo', returnApi[0].codigo);
        localStorage.setItem('@vevee/usuarionome', returnApi[0].nome);
        Alert.success('Bem vindo(a) a Vevee!', { position: 'top', effect: 'slide', timeout: 'none' });
        window.location.href = "/";
      }

    });
    xhr.open('GET', 'https://app.vevee.com.br/apiinterna/login?email=' + state.email + '&senha=' + state.senha);
    xhr.send();
  }

  render(){
      return(
        <div id="baseContainer" style={{margin: '0', backgroundImage: 'url(https://app.vevee.com.br/app_files/images/bg-login.jpg)', backgroundSize: 'cover'}}>
          <Container>
            <img src={ Logo } alt="Vevee" />
            
            <h1>Bem vindo(a)!</h1>
            
            <Form onSubmit={this.handleSubmit}>
              <input type="text" placeholder="E-mail" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} style={{textTransform: 'lowercase'}} required /><br />
              <input type="password" placeholder="Senha" value={this.state.senha} onChange={(e) => this.setState({senha: e.target.value})} required /><br />

              <SubmitButton>Entrar</SubmitButton>
            </Form>
            
            <div>Esqueci minha senha <Link to={'./esqueciminhasenha'}>Clique aqui.</Link></div>
            <div>Não tenho cadastro! <Link to={'./cadastro'}>Clique aqui.</Link></div>
          </Container>
          <Alert stack={{limit: 3}} />
        </div>
      );
  }

}

export default Login;