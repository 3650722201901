import React, { Component } from 'react';
import Routes from './routes';

import GlobalStyle from './styles/global';

class App extends Component{

  constructor(props){
    super(props);
    this.state = {
          
    };
  }

  render(){
      return(
        <>
          <GlobalStyle />
          <Routes />
        </>
      );
  }

}

export default App;