import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import { FaAngleLeft } from 'react-icons/fa';

import { Container, Form, SubmitButton } from './styles';

class Cadastro extends Component{

  constructor(props){
    super(props);
    this.state = {
      nome: '',
      cpf: '',
      celular: '',
      email: '',
      senha: '',
      indicacao: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.letraMaiuscula = this.letraMaiuscula.bind(this);
  }

  letraMaiuscula(string){
    return string.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }

  handleSubmit(event) {
    event.preventDefault();
    let state = this.state;

    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      
      var returnApi = JSON.parse(xhr.response);
      
      Alert.closeAll();
      if(returnApi[0].status == false){
        Alert.error(returnApi[0].mensagem, { position: 'top', effect: 'slide', timeout: 'none' });
      }
      else{
        localStorage.setItem('@vevee/usuariocodigo', returnApi[0].codigo);
        localStorage.setItem('@vevee/usuarionome', returnApi[0].nome);
        Alert.success('Bem vindo(a) a Vevee!', { position: 'top', effect: 'slide', timeout: 'none' });
        window.location.href = "/";
      }

    });
    xhr.open('GET', 'https://app.vevee.com.br/apiinterna/cadastro?nome=' + state.nome + '&email=' + state.email + '&senha=' + state.senha + '&cpf=' + state.cpf + '&celular=' + state.celular + '&indicacao=' + state.indicacao);
    xhr.send();
  }

  componentDidMount () {
    const { indicacao } = this.props.match.params;

    let state = this.state;
    state.indicacao = indicacao;
    this.setState(state);

    console.log(state.indicacao);
  }
  

  render(){
      return(
        <div id="baseContainer" style={{margin: '0', backgroundImage: 'url(https://app.vevee.com.br/app_files/images/bg-cadastro.jpg)', backgroundSize: 'cover'}}>
          <Container>
            <Link to={'./login'}><FaAngleLeft /></Link>

            <h1>Cadastro</h1>
                    
            <Form onSubmit={this.handleSubmit}>
              <InputMask type="text" placeholder="Nome" value={this.state.nome} onChange={(e) => this.setState({nome: e.target.value})} required /><br />
              <InputMask type="text" placeholder="CPF" value={this.state.cpf} onChange={(e) => this.setState({cpf: e.target.value})} required mask="999.999.999-99" /><br />
              <InputMask type="text" placeholder="Celular" value={this.state.celular} onChange={(e) => this.setState({celular: e.target.value})} required mask="(99) 99999-9999" /><br />
              <InputMask type="email" placeholder="E-mail" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} style={{textTransform: 'lowercase'}} required /><br />
              <InputMask type="password" placeholder="Senha" value={this.state.senha} onChange={(e) => this.setState({senha: e.target.value})} required /><br />

              <SubmitButton>Cadastrar</SubmitButton>
            </Form>

            <div>Já tenho uma conta! <Link to={'./login'}>Clique aqui.</Link></div>
          </Container>
          <Alert stack={{limit: 3}} />
        </div>
      );
  }

}

export default Cadastro;