import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import NavPerfil from '../../components/NavPerfil';
import Logo from '../../images/logo-vevee-color.svg';

import { FaCreditCard, FaCalendar, FaUsers, FaUser, FaTimes, FaIdCard } from 'react-icons/fa';

import { Container, Form, SubmitButton } from './styles';

class MinhaCarteirinha extends Component{

  constructor(props){
    super(props);
    this.state = {
      nome: '',
      cartao: ''
    };

    if(localStorage.getItem('@vevee/usuariocodigo')){
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', () => {
        let state = this.state;
        var returnApi = JSON.parse(xhr.response);

        state.nome = returnApi[0].nome;
        state.cartao = returnApi[0].cartao;
        this.setState(state);
  
      });
      xhr.open('GET', 'https://app.vevee.com.br/apiinterna/perfil?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhr.send();
    }

  }

  render(){
      return(
        <>
          <NavPerfil />

          <div id="baseContainer">
            <Container>
              <nav>
                <ul>
                  <li><Link to={'./minhasconsultas'}><FaCalendar /> Consultas</Link></li>
                  <li><Link to={'./meusdependentes'}><FaUsers /> Dependentes</Link></li>
                  <li><Link to={'./meuscartoes'}><FaCreditCard /> Meios de Pagamento</Link></li>
                  <li><Link to={'./minhacarteirinha'}><FaIdCard /> Carteirinha</Link></li>
                  <li><Link to={'./meuperfil'}><FaUser /> Perfil</Link></li>
                  <li style={{borderTop: '1px solid #CCC', marginTop: '10px', paddingTop: '20px'}}><Link to={'./login'}><FaTimes /> Sair</Link></li>
                </ul>
              </nav>

              <div id="col-perfil">
                <h1>Carteirinha</h1>
              </div>
              <br clear="all" />

              <div id="col-form">
                <div id="carteirinha">
                    <img src={ Logo } alt="Vevee" />
                    <h1>{this.state.cartao}</h1>
                    <h2>{this.state.nome}</h2>
                </div>
              </div>    
            </Container>
            <Alert stack={{limit: 3}} />
          </div>
        </>
      );
  }

}

export default MinhaCarteirinha;