import styled from 'styled-components';

export const Container = styled.div `

    height: 100%;
    background: #FFF;
    width: 350px;
    padding: 0 100px;
    text: align: center;

    div{
        margin-bottom: 10px;
        text: align: center;
    }

    .titulo{
        color: #373a3c;
        font-size: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #CCC;
        padding: 10px;
        margin-bottom: 20px
    }

    img {
        width: 150px;
        margin: 50px 0 20px 0;
    }


    @media (max-width: 600px) {

        width: 90%;
        margin: 0 auto;
        padding: 20px;

    }

`;

export const Form = styled.form `
    
    input{
        flex: 1;
        padding: 15px;
        margin: 10px auto 20px;
        border-radius: 4px; 
        border: 1px solid #DDD;
        width: 90%;
    }

    .col-lef-50{
        width: 45%;
        float: left;
    }
    .col-rig-50{
        width: 45%;
        float: right;
    }

    .col-lef-50 input, .col-rig-50 input {
        width: 78%;
    }

`;

export const SubmitButton = styled.button `

    flex: 1;
    padding: 10px 15px;
    display: table;
    border: none;
    border-radius: 4px;    
    background: #1F2F59;
    color: #FFF;
    width: 100%;
    margin: 5px auto 0;
    text-transform: uppercase;
    margin-bottom: 30px;

`;