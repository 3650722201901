import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import firebase from '../../firebaseConnection';
import Nav from '../../components/Nav';

import { FaStar, FaAngleLeft, FaMapMarkerAlt, FaUserMd, FaHospital, FaHeartbeat, FaTooth, FaCheck, FaTimes, FaTrash } from 'react-icons/fa';

import { Container, Resultado, HeaderResultado, Form, BuscaItem, SubmitButton, Map, ButtonMais, PinFarmacia, PinDentista, PinLaboratorio, PinMedico, PinAtual, Endereco, ButtonAgendar, Data, Horarios, ButtonFinalizar, MeioDePagamento, FormaPagamento, Confirmacao } from './styles';

class Home extends Component{

  constructor(props){
    super(props);
    this.state = {
      mapsKey: 'AIzaSyC0mQyhLRLM3ezirUCjq3cjiBP5WhtLgN8',
      mapsCenter: { lat: -19.9191197, lng: -43.9408178 },
      mapsZoom: 14,
      usuario: [],
      usuarioDependentes: [],
      usuariosCartoes: [],
      especialidadeLista: [],
      especialidadeTipo: '',
      form:{
        localizacao: '',
        especialidade: '',
        produto: ''
      },
      carrinhoProdutos: [],
      buscaResultado: [],
      buscaTipo: '',
      profissionalKey: '',
      profissionalResultado: [],
      profissionalAgenda: [],
      profissionalAgendaHorarios: [],
      mensagem: '',
      consultaPara: '',
      consulta: {
        data: '',
        horario: ''
      },
    };

    this.letraMaiuscula = this.letraMaiuscula.bind(this);
    this.dadosForm = this.dadosForm.bind(this);
    this.backBusca = this.backBusca.bind(this);
    this.fechar = this.fechar.bind(this);
    this.buscaEspecialidades = this.buscaEspecialidades.bind(this);
    this.carrinhoAdd = this.carrinhoAdd.bind(this);
    this.carrinhoDel = this.carrinhoDel.bind(this);
    this.buscaProdutos = this.buscaProdutos.bind(this);
    this.buscaHorarios = this.buscaHorarios.bind(this);
    this.setConsulta = this.setConsulta.bind(this);
    this.setPagamento = this.setPagamento.bind(this);
    this.pageCheckout = this.pageCheckout.bind(this);
    this.pageConfirmacao = this.pageConfirmacao.bind(this);

    let state = this.state;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          state.mapsCenter = { lat: position.coords.latitude, lng: position.coords.longitude }
          this.setState(state);
        }
      )
    }

    state.especialidadeLista.push({
      key: 0,
      especialidade: 'Escolha uma opção'
    })
    this.setState(state);    

  }

  componentDidMount(){
    if(localStorage.getItem('@vevee/produtoKey')){
      this.pageCheckout(localStorage.getItem('@vevee/produtoKey'));
    }

    var tipo = this.props.location.pathname;
    if(tipo === '/medicos'){
      this.buscaEspecialidades('Medicos');
    }
    else if(tipo === '/exames'){
      this.buscaEspecialidades('Exames');
    }
    const { url } = this.props.match.params;
    if(url){
      var id = url.split('__');
      id = id[1];
      if(id){
        this.pageCheckout(id);
      }
    }
  }

  letraMaiuscula(string){
    return string.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }


  dadosForm(e){
    let form = this.state.form;

    if(e.target.name === 'bairro'){
      form[e.target.name] = this.letraMaiuscula(e.target.value);
    }else{
      form[e.target.name] = e.target.value;
    }

    this.setState({form: form});
  }
  
  
  backBusca() {
    document.getElementById('buscaContainer').style.setProperty('display', 'block');
    document.getElementById('resultadoContainer').style.setProperty('display', 'none');
  }


  fechar() {
    localStorage.removeItem('@vevee/produtoKey');

    let state = this.state;
    state.consultaHora = '';
    state.consultaData = '';
    state.consultaPara = '';
    state.consultaFormaPagamento = '';
    state.consultaValor = '';
    this.setState(state);

    document.getElementById('mostraHorarios').style.setProperty('display', 'none');
    document.getElementById('resumoDataHorario').style.setProperty('display', 'none');
    document.getElementById('resumoDataHorario2').style.setProperty('display', 'none');
    document.getElementById('botaoFinalizarPagamento').style.setProperty('display', 'none');
    document.getElementById('lightbox-confirmacao').style.setProperty('display', 'none');
    document.getElementById('lightbox-bg').style.setProperty('display', 'none');
    document.getElementById('lightbox').style.setProperty('display', 'none');
  }


  buscaEspecialidades(tipo){
    let state = this.state;
    state.especialidadeTipo = tipo;
    state.especialidadeLista = [];
    state.carrinhoProdutos = [];
    state.form.produto = '';
    state.especialidadeLista.push({
      key: 0,
      especialidade: 'Carregando...'
    })
    this.setState(state);

    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      var returnApi = JSON.parse(xhr.response);
      state.especialidadeLista = [];

      if(returnApi != null){
        state.especialidadeLista.push({
          key: 0,
          especialidade: 'Escolha uma opção'
        })
        for(var i = 0; i < returnApi.length; i++){ 
          state.especialidadeLista.push({
            key: returnApi[i].id,
            especialidade: returnApi[i].especialidade
          })
        }
      }

      this.setState(state);
    });
    xhr.open('GET', 'https://app.vevee.com.br/apiinterna/especialidades?tipo=' + state.especialidadeTipo);
    xhr.send();

    document.getElementById('especialideTipoMedico').style.setProperty('color', '#7D7D7D');
    document.getElementById('especialideTipoDentista').style.setProperty('color', '#7D7D7D');
    document.getElementById('especialideTipoLaboratorios').style.setProperty('color', '#7D7D7D');
    document.getElementById('especialideTipoFarmacia').style.setProperty('color', '#7D7D7D');

    switch (tipo) {
      case "Dentistas":
        document.getElementById('especialideTipoDentista').style.setProperty('color', '#00B4BE');
        document.getElementById('formEspecialidade').style.setProperty('display', 'block');
        document.getElementById('formLocalizacao').style.setProperty('display', 'block');
        document.getElementById('formProdutos').style.setProperty('display', 'none');
      break;
      case "Exames":
        document.getElementById('especialideTipoLaboratorios').style.setProperty('color', '#00B4BE');
        document.getElementById('formEspecialidade').style.setProperty('display', 'none');
        document.getElementById('formLocalizacao').style.setProperty('display', 'none');
        document.getElementById('formProdutos').style.setProperty('display', 'block');
      break;
      case "Farmacias":
        document.getElementById('especialideTipoFarmacia').style.setProperty('color', '#00B4BE');
        document.getElementById('formEspecialidade').style.setProperty('display', 'none');
        document.getElementById('formLocalizacao').style.setProperty('display', 'none');
        document.getElementById('formProdutos').style.setProperty('display', 'block');
      break;
      default:
        document.getElementById('especialideTipoMedico').style.setProperty('color', '#00B4BE');
        document.getElementById('formEspecialidade').style.setProperty('display', 'block');
        document.getElementById('formLocalizacao').style.setProperty('display', 'block');
        document.getElementById('formProdutos').style.setProperty('display', 'none');
      break;
    }
  }


  carrinhoAdd(){
    let state = this.state;

    // VERIFICA SE JA EXISTE PRODUTO COM O MESMO NOME
    var arr = state.carrinhoProdutos;
    arr = arr.filter(item => item !== state.form.produto);
    state.carrinhoProdutos = arr;

    // ADD PRODUTO AO CARRINHO
    if(state.form.produto !== ''){
      state.carrinhoProdutos.push(state.form.produto);
    }

    state.form.produto = '';

    this.setState(state);
  }


  carrinhoDel(produto){
    let state = this.state;
    var arr = state.carrinhoProdutos;
    arr = arr.filter(item => item !== produto);

    state.carrinhoProdutos = arr;
    this.setState(state);
  }


  buscaProdutos(event) {
    event.preventDefault();

    let state = this.state;
    state.buscaTipo = state.especialidadeTipo;

    // LIMPA FORM
    if(state.buscaTipo == 'Exames' || state.buscaTipo == 'Farmacias'){
      state.form.especialidade = '';
      state.form.localizacao = '';
    }
    else{
      state.form.produto = '';
    }
    
    state.mensagem = 'Aguarde, procurando...';
    state.buscaResultado = [];
    this.setState(state);

      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', () => {
        var returnApi = JSON.parse(xhr.response);
        state.buscaResultado = [];

        if(returnApi === null){
          state.mensagem = 'Nenhum resultado encontrado com esse filtro. Tente outra opção.';
        }
        else{
          state.mensagem = '';

          for(var i = 0; i < returnApi.length; i++){ 
            state.buscaResultado.push({            
              key: returnApi[i].id,
              nome: returnApi[i].nome,
              medico: returnApi[i].medico,
              especialidades: returnApi[i].especialidades,
              endereco: returnApi[i].endereco,
              bairro: returnApi[i].bairro,
              cidade: returnApi[i].cidade,
              valorDe: returnApi[i].valorDe,
              valorPor: returnApi[i].valorPor,
              proximoHorario: returnApi[i].proximoHorario,
              horarios: returnApi[i].horarios,
              latitude: returnApi[i].latitude,
              longitude: returnApi[i].longitude,
              url: returnApi[i].url,
              distancia: returnApi[i].distancia,
              foto: returnApi[i].foto,
              produtos: returnApi[i].produtos
            })
          }
        }

        this.setState(state);
      });
      xhr.open('GET', 'https://app.vevee.com.br/apiinterna/produtos?tipo=' + state.buscaTipo + '&especializacao=' + state.form.especialidade + '&local=' + state.form.localizacao + '&carrinho=' + state.carrinhoProdutos + '&geo=' + state.mapsCenter.lat + ',' + state.mapsCenter.lng);
      xhr.send();

      document.getElementById('buscaContainer').style.setProperty('display', 'none');
      document.getElementById('resultadoContainer').style.setProperty('display', 'block');
    
  }


  buscaHorarios(id, horarios){
    let state = this.state;
    var i = 0;

    let elms = document.getElementsByClassName('data-reset');
    for(i = 0; i < elms.length; i++) {
      elms[i].style.background = '#F5F5F5';
      elms[i].style.color = '#7D7D7D';
    }

    elms = document.getElementsByClassName('hora-reset');
    for(i = 0; i < elms.length; i++) {
      elms[i].style.background = '#F5F5F5';
      elms[i].style.color = '#7D7D7D';
    }

    state.profissionalAgendaHorarios = horarios.split('-');
    state.consulta.data = id;
    this.setState(state);

    document.getElementById(id).style.setProperty('background', '#CCC');
    document.getElementById(id).style.setProperty('color', '#385170');
    document.getElementById('mostraHorarios').style.setProperty('display', 'flex');
    document.getElementById('resumoDataHorario').style.setProperty('display', 'none');
    document.getElementById('resumoDataHorario2').style.setProperty('display', 'none');
      
  }


  setConsulta(hora){
    let state = this.state;
    var i = 0;

    let elms = document.getElementsByClassName('hora-reset');
    for(i = 0; i < elms.length; i++) {
      elms[i].style.background = '#F5F5F5';
      elms[i].style.color = '#7D7D7D';
    }

    document.getElementById(hora).style.setProperty('background', '#CCC');
    document.getElementById(hora).style.setProperty('color', '#385170');

    state.consulta.horario = hora;
    this.setState(state);

    document.getElementById('resumoDataHorario').style.setProperty('display', 'block');
    document.getElementById('resumoDataHorario2').style.setProperty('display', 'block');

  }


  setPagamento(id){
    let state = this.state;
    let elms = document.getElementsByClassName('pagamento-reset');

    state.consultaFormaPagamento = id;
    this.setState(state);

    for(var i = 0; i < elms.length; i++) {
      elms[i].style.background = '#F5F5F5';
      elms[i].style.color = '#7D7D7D';
    }
    
    document.getElementById('botaoFinalizarPagamento').style.setProperty('display', 'block');
    document.getElementById(id).style.setProperty('background', '#CCC');
    document.getElementById(id).style.setProperty('color', '#385170');
  }


  pageCheckout(key) {
    localStorage.setItem('@vevee/produtoKey', key);

    document.getElementById('lightbox-bg').style.setProperty('display', 'block');
    document.getElementById('lightbox-loading').style.setProperty('display', 'block');

    let state = this.state;

    if(!localStorage.getItem('@vevee/usuariocodigo')){
      document.getElementById('botaoCadastrar').style.setProperty('display', 'block');
      document.getElementById('divMeioDePagamento').style.setProperty('display', 'none');
      document.getElementById('divConsultaPara').style.setProperty('display', 'none');
    }
    else{
      // PEGA INFOS PERFIL
      var xhrPerfil = new XMLHttpRequest();
      xhrPerfil.addEventListener('load', () => {
        let state = this.state;
        var returnPerfil = JSON.parse(xhrPerfil.response);

        state.usuarioNome = returnPerfil[0].nome;
        state.usuarioEmail = returnPerfil[0].email;
        state.usuarioCpf = returnPerfil[0].cpf;
        state.usuarioCelular = returnPerfil[0].celular;
        state.usuarioDataNascimento = returnPerfil[0].dataNascimento;
        state.zoopIdComprador = returnPerfil[0].zoopIdComprador;
        this.setState(state);
      });
      xhrPerfil.open('GET', 'https://app.vevee.com.br/apiinterna/perfil?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhrPerfil.send();

      // PEGA INFOS DEPENDENTES
      var xhrDependentes = new XMLHttpRequest();
      xhrDependentes.addEventListener('load', () => {
        let state = this.state;
        var returnDependentes = JSON.parse(xhrDependentes.response);
        state.usuarioDependentes = [];

        if(returnDependentes != null){
          for(var i = 0; i < returnDependentes.length; i++){ 
            state.usuarioDependentes.push({
              key: returnDependentes[i].id,
              nome: returnDependentes[i].nome,
              cpf: returnDependentes[i].cpf,
              dataNascimento: returnDependentes[i].dataNascimento,
              celular: returnDependentes[i].celular,
              tipo: returnDependentes[i].tipo
            })
          }
        }
        this.setState(state);
      });
      xhrDependentes.open('GET', 'https://app.vevee.com.br/apiinterna/dependentes?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhrDependentes.send();

      // PEGA INFOS CARTAO
      var xhrCartoes = new XMLHttpRequest();
      xhrCartoes.addEventListener('load', () => {
        let state = this.state;
        var returnCartoes = JSON.parse(xhrCartoes.response);
        state.usuariosCartoes = [];

        if(returnCartoes != null){
          for(var i = 0; i < returnCartoes.length; i++){ 
            state.usuariosCartoes.push({
              key: returnCartoes[i].id,
              nome: returnCartoes[i].nome,
              numero: returnCartoes[i].numero,
              bandeira: returnCartoes[i].bandeira
            })
          }
          document.getElementById('mensagemMeioPagamento').style.setProperty('display', 'none');
        }
        else{
          document.getElementById('mensagemMeioPagamento').style.setProperty('display', 'block');
        }
        this.setState(state);
      });
      xhrCartoes.open('GET', 'https://app.vevee.com.br/apiinterna/cartoes?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhrCartoes.send();
    }

    // PEGA INFOS DO PRODUTO
    var xhrProduto = new XMLHttpRequest();
    xhrProduto.addEventListener('load', () => {
      let state = this.state;
      var returnProduto = JSON.parse(xhrProduto.response);

      state.profissionalResultado = returnProduto[0];
      state.profissionalKey = key;
      state.consultaValor = returnProduto[0].valorPor;
      state.profissionalAgenda = returnProduto[0].horarios.split(';');
      this.setState(state);

      var i = 0;
      let elms = document.getElementsByClassName('data-reset');
      for(i = 0; i < elms.length; i++) {
        elms[i].style.background = '#F5F5F5';
        elms[i].style.color = '#7D7D7D';
      }

      elms = document.getElementsByClassName('hora-reset');
      for(i = 0; i < elms.length; i++) {
        elms[i].style.background = '#F5F5F5';
        elms[i].style.color = '#7D7D7D';
      }

      document.getElementById('lightbox').style.setProperty('display', 'block');
      document.getElementById('lightbox-loading').style.setProperty('display', 'none');
      document.getElementById('lightbox-checkout').style.setProperty('display', 'table');
    });
    xhrProduto.open('GET', 'https://app.vevee.com.br/apiinterna/produto?tipo=' + state.buscaTipo + '&id=' + key + '&carrinho=' + state.carrinhoProdutos);
    xhrProduto.send();
    
  }


  pageConfirmacao() {
    document.getElementById('botaoFinalizarPagamento').text = 'Aguarde, processando pagamento...';

    let state = this.state;

    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      
      var returnApi = JSON.parse(xhr.response);

      Alert.closeAll();
      if(returnApi[0].status == false){
        Alert.error(returnApi[0].mensagem, { position: 'top', effect: 'slide', timeout: 'none' });
        document.getElementById('botaoFinalizarPagamento').text = 'Finalizar pagamento';
      }
      else{
        document.getElementById('lightbox-checkout').style.setProperty('display', 'none');
        document.getElementById('lightbox-confirmacao').style.setProperty('display', 'block');
        document.getElementById('botaoFinalizarPagamento').text = 'Finalizar pagamento';
      }

    });
    xhr.open('GET', 'https://app.vevee.com.br/apiinterna/consulta?tipo=' + state.buscaTipo + '&codigo=' + localStorage.getItem('@vevee/usuariocodigo') + '&produto=' + state.profissionalKey + '&para=' + state.consultaPara + '&data=' + state.consulta.data + '&horario=' + state.consulta.horario + '&carrinho=' + state.carrinhoProdutos);
    xhr.send();   
  }


  render(){
      let state = this.state;

      return(
        <>
          <Map>
            <div style={{ height: '100vh', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: state.mapsKey }}
                center={state.mapsCenter}
                defaultZoom={state.mapsZoom}
              >
              <PinAtual lat={state.mapsCenter.lat} lng={state.mapsCenter.lng} />
              {state.buscaResultado.map((item) => {
                switch (state.buscaTipo) {
                  case 'Dentistas':
                    return( <PinDentista lat={item.latitude} lng={item.longitude} key={item.key} onClick={() => this.pageCheckout(item.key)}></PinDentista> );
                  case 'Exames':
                    return( <PinLaboratorio lat={item.latitude} lng={item.longitude} key={item.key} onClick={() => this.pageCheckout(item.key)}></PinLaboratorio> );
                  case 'Farmacias':
                    return( <PinFarmacia lat={item.latitude} lng={item.longitude} key={item.key} onClick={() => this.pageCheckout(item.key)}></PinFarmacia> );
                  default:
                    return( <PinMedico lat={item.latitude} lng={item.longitude} key={item.key} onClick={() => this.pageCheckout(item.key)}></PinMedico> );
                }
              })}
              </GoogleMapReact>
            </div>
          </Map>

          <Nav />

          <div id="buscaContainer" style={{padding: '0', width: '100%'}}>
            <Container>
              <h1 className="tituloSessao">O que você procura?</h1>
              <Form onSubmit={this.buscaProdutos}>
                <ul>
                  <li><Link id="especialideTipoMedico" to={'../medicos'} onClick={() => { this.buscaEspecialidades("Medicos"); }}><FaUserMd /><br />Médicos</Link></li>
                  <li><Link id="especialideTipoLaboratorios" to={'../exames'} onClick={() => { this.buscaEspecialidades("Exames"); }}><FaHeartbeat /><br />Exames</Link></li>
                  <li style={{display: 'none'}}><a id="especialideTipoDentista" onClick={() => { this.buscaEspecialidades("Dentistas"); }}><FaTooth /><br />Odontologia</a></li>
                  <li style={{display: 'none'}}><a id="especialideTipoFarmacia" onClick={() => { this.buscaEspecialidades("Farmacias"); }}><FaHospital /><br />Farmácias</a></li>
                </ul>

                <br clear="all" />

                <div className="div-50" id="formEspecialidade">
                  <label>Qual a especialização você deseja?</label>
                  <select name="especialidade" value={state.form.especialidade} required onChange={this.dadosForm}>
                    {state.especialidadeLista.map((item) => {
                      return(
                        <option key={item.especialidade}>{item.especialidade}</option>
                      );
                    })}
                  </select>
                </div>

                <div className="div-50" id="formLocalizacao">
                  <label>Preferência de um bairro?</label>
                  <input type="text" placeholder="Informe um bairro" name="localizacao" value={state.form.localizacao} onChange={this.dadosForm}></input>
                </div>

                <div id="formProdutos">
                  <div className="div-50" style={{marginLeft: '100px'}}>
                    <label>Buscar um exame ou produto</label>
                    <input type="text" placeholder="Informe um exame ou produto" name="produto" value={state.form.produto} onChange={this.dadosForm}></input>
                  </div>
                  <div className="div-50">
                    <ButtonMais style={{width: '100px', marginTop: '30px'}} onClick={() => { this.carrinhoAdd(); }}>Adicionar</ButtonMais>
                  </div>
                  <br clear="all" />

                  <ul id="listaProdutos">
                    {state.carrinhoProdutos.map((item) => {
                      return(
                        <li key={item}>{item}<a onClick={() => { this.carrinhoDel(item); }}><FaTrash /></a></li>
                      );
                    })}
                  </ul>

                </div>

                <br clear="all" />

                <SubmitButton>Buscar</SubmitButton>
                
              </Form>
            </Container>
            <iframe src="https://app.vevee.com.br/" width="100%" height="3500" frameBorder="no" scrolling="no" style={{marginTop: '50px'}}></iframe>
            <Alert stack={{limit: 3}} />
          </div>


          <div id="resultadoContainer">
            <HeaderResultado>
              <h1><a onClick={this.backBusca}><FaAngleLeft /></a> Resultado da Busca</h1>
            </HeaderResultado>

            <Resultado>
              {state.buscaResultado.map((item) => {
                
                return(
                  <BuscaItem>
                    <div>
                      <img src={item.foto} />
                      <h1>{item.medico}</h1>
                      <p>{item.especialidades}</p>
                      <p>Próximo horário: {item.proximoHorario}</p>
                      <br clear="all"/>
                      <address>
                        <h2><FaMapMarkerAlt /> {item.nome} <span style={{color: '#666', fontSize: '13px'}}>({item.distancia})</span></h2>
                        <p>{item.endereco}, {item.bairro} - {item.cidade}</p>
                      </address>
                    </div>
                    <div>
                      <span>No consultório: <span style={{textDecoration: 'line-through'}}>R$ {item.valorDe}</span></span>
                      <span style={{float: 'right'}}>Pela Vevee: <span style={{color: '#13365C'}}>R$ {item.valorPor}</span></span>

                      <br clear="all" />

                      <div dangerouslySetInnerHTML={{ __html: item.produtos }} />

                      <br clear="all" />

                      <Link to={item.url} onClick={() => this.pageCheckout(item.key)} key={item.key}><ButtonAgendar>Agendar</ButtonAgendar></Link>

                      <blockquote>
                        “Atencioso gostei muito agora no tratamento vamos ter o feedback da eficácia… Espero funcionar mas ele me deixou whats a consulta foi online foi ótimo.”
                        <p>28 opiniões <FaStar style={{color: '#FFBB39'}} /><FaStar style={{color: '#FFBB39'}} /><FaStar style={{color: '#FFBB39'}} /><FaStar style={{color: '#FFBB39'}} /><FaStar /> </p>
                      </blockquote>
                    </div>
                  </BuscaItem>
                );
              })}
              <p id="mensagem">{state.mensagem}</p>
            </Resultado>           
          </div>

          <div id="lightbox-bg">
            <a onClick={this.fechar}></a>
          </div>

          <div id="lightbox-loading">
            <img src="https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/cupertino_activity_indicator_square_small.gif" />
          </div>

          <div id="lightbox">
            <h1 className="titulo">Marcação da Consulta<a onClick={this.fechar}><FaTimes /></a></h1>
            <div id="lightbox-checkout">
              <div className="col-lef-50">
                <img src={state.profissionalResultado.foto} />
                <h1>{state.profissionalResultado.medico}</h1>
                <p>{state.profissionalResultado.especialidades}</p>
                <br clear="all"/>

                <h4><FaMapMarkerAlt /> {state.profissionalResultado.nome}</h4>
                <p>{state.profissionalResultado.endereco}, {state.profissionalResultado.bairro} - {state.profissionalResultado.cidade}</p>
                
                <div dangerouslySetInnerHTML={{ __html: state.profissionalResultado.produtos }} />
              </div>
              <div className="col-rig-50">
                <h2 style={{marginBottom: '23px'}}>Valor pela Vevee: R$ {state.profissionalResultado.valorPor}</h2>

                <h1 className="subtitulo">Escolha uma data e horário</h1>
                  <Data>
                    {state.profissionalAgenda.map((item) => {
                      var data = item.split(',');
                      return(
                        <a className="data-reset" key={data[1]} onClick={() => this.buscaHorarios(data[1], data[2])} id={data[1]}><span>{data[0]}</span><p>{data[1]}</p></a>
                      );
                    })}
                  </Data>
                  <Horarios id="mostraHorarios">
                    {state.profissionalAgendaHorarios.map((item) => {
                      return(
                        <a className="hora-reset" onClick={() => this.setConsulta(item)} id={item} key={item}>{item}</a>
                      );
                    })}
                  </Horarios>
              
                <div id="resumoDataHorario">
                  <h1 className="subtitulo">Paciente da Consulta</h1>
                  <Endereco id="divConsultaPara">
                    <select required onChange={(e) => this.setState({consultaPara: e.target.value})}>
                      <option value={state.usuarioNome} selected>{state.usuarioNome}</option>
                      {state.usuarioDependentes.map((item) => {
                      return(
                        <option key={item.nome}>{item.nome} - {item.tipo}</option>
                      );
                    })}
                    </select>
                  </Endereco>
                </div>
                <div id="resumoDataHorario2">
                  <h1 className="subtitulo">Meio de Pagamento</h1>
                  <div id="mensagemMeioPagamento">
                    <p>Você precisa cadastrar um cartão para finalizar a compra.</p>
                    <Link to={'../meuscartoes'}>Cadastrar</Link>
                  </div>
                  <MeioDePagamento id="divMeioDePagamento">
                    <FormaPagamento>
                    {state.usuariosCartoes.map((item) => {
                      return(
                          <a className="pagamento-reset" id={item.key} onClick={() => this.setPagamento(item.key)}>
                            <img src="https://logospng.org/download/mastercard/logo-mastercard-256.png" />
                            <div>
                              <h2>{item.nome}</h2>
                              <h3 style={{background: 'none'}}>{item.numero}</h3>
                            </div>
                          </a>
                      );
                    })}
                    </FormaPagamento>
                  </MeioDePagamento>
                </div>

                <ButtonFinalizar onClick={this.pageConfirmacao} id="botaoFinalizarPagamento">Finalizar pagamento</ButtonFinalizar>
                <Link to={'../login'}><ButtonFinalizar id="botaoCadastrar">Fazer cadastro ou login</ButtonFinalizar></Link>
              </div>
            </div>
            <div id="lightbox-confirmacao">
              <Confirmacao>
                <FaCheck />
                <h1>Consulta Agendada</h1>
                <p>Sua consulta com {state.profissionalResultado.nome} no dia {state.consulta.data} às {state.consulta.horario} está confirmada.</p>
                <a onClick={this.fechar}>Fechar</a>
              </Confirmacao>
            </div>
            <Alert stack={{limit: 3}} />
          </div>
        </>
      );
  }

}


export default Home;