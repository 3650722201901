import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Cadastro from './pages/Cadastro';
import EsqueciMinhaSenha from './pages/EsqueciMinhaSenha';
import Login from './pages/Login';
import MeuPerfil from './pages/MeuPerfil';
import MeusCartoes from './pages/MeusCartoes';
import MinhasConsultas from './pages/MinhasConsultas';
import MeusDependentes from './pages/MeusDependentes';
import MinhaCarteirinha from './pages/MinhaCarteirinha';

export default function Routes(){

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={ Home } />
                <Route exact path="/medicos" component={ Home } />
                <Route exact path="/medicos/:url" component={ Home } />
                <Route exact path="/exames" component={ Home } />
                <Route exact path="/exames/:url" component={ Home } />

                <Route exact path="/cadastro" component={ Cadastro } />
                <Route path="/cadastro/:indicacao" component={ Cadastro } />
                <Route exact path="/esqueciminhasenha" component={ EsqueciMinhaSenha } />
                <Route exact path="/login" component={ Login } />
                <Route exact path="/meuperfil" component={ MeuPerfil } />
                <Route exact path="/meuscartoes" component={ MeusCartoes } />
                <Route exact path="/minhasconsultas" component={ MinhasConsultas } />
                <Route exact path="/meusdependentes" component={ MeusDependentes } />
                <Route exact path="/minhacarteirinha" component={ MinhaCarteirinha } />
            </Switch>
        </BrowserRouter>
    );

}