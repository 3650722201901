import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FaBars } from 'react-icons/fa';

import { NavBar, NavBarMobile, Usuario, MenuUsuario, Container, MenuItem, MenuItemButton, SubMenu } from './styles';

import Logo from '../../images/logo-vevee-color.svg';

class NavPerfil extends Component{

    constructor(props){
      super(props);
      this.state = {
        usuarioNome: '',
        setLinks: '',
        submenu: 'none'
      };

      let state = this.state;

      this.submenu = this.submenu.bind(this);

      if(localStorage.getItem('@vevee/usuariocodigo')){
        state.usuarioNome = localStorage.getItem('@vevee/usuarionome');
        state.setLinks = <div style={{marginTop:'5px'}}><Link to={'./meuperfil'}>Meu Perfil</Link><Link to={'./login'}>Sair</Link></div>;
        state.menuLogado = 'block';
        this.setState(state);
      }
      else{
        window.location.href = "/";
      }
    }

    submenu() {

      let state = this.state;

      if(state.submenu == 'none'){
        document.getElementById('submenu').style.setProperty('display', 'block');
        state.submenu = 'block';
      }
      else{
        document.getElementById('submenu').style.setProperty('display', 'none');
        state.submenu = 'none';
      }
      this.setState(state);
      
    }

    render(){
      let state = this.state;

      return(
        <>
          <NavBar>
            <Container>
                <img src={ Logo } alt="Vevee" />
                <Link to={'./'}><MenuItemButton>AGENDAR CONSULTA</MenuItemButton></Link>
            </Container>
            
            <Usuario>
                <div>
                  <p>Olá, {state.usuarioNome}</p>
                </div>
                <img src="https://i0.wp.com/www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png?fit=256%2C256&quality=100&ssl=1" alt={state.usuarioNome} /><br />
            </Usuario>
          </NavBar>

          <NavBarMobile>
            <Container>
                <Link onClick={this.submenu}><MenuItem><FaBars /></MenuItem></Link>
                <img src={ Logo } alt="Vevee" />
            </Container>            
          </NavBarMobile>

          <SubMenu id="submenu">
                <Link to={'./'}>Agendar Consulta</Link>
                <Link to={'./minhasconsultas'}>Consultas</Link>
                <Link to={'./meusdependentes'}>Dependentes</Link>
                <Link to={'./meuscartoes'}>Meios de Pagamento</Link>
                <Link to={'./meuperfil'}>Perfil</Link>

                <p>{state.usuarioNome}<br /><br />
                {state.setLinks}
                </p>
          </SubMenu>
        </>
      );
    }
  
  }
  
  export default NavPerfil;