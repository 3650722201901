import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import NavPerfil from '../../components/NavPerfil';

import { FaCreditCard, FaCalendar, FaUsers, FaUser, FaTimes, FaIdCard } from 'react-icons/fa';

import { Container, Form, SubmitButton } from './styles';

class MeuPerfil extends Component{

  constructor(props){
    super(props);
    this.state = {
      nome: '',
      cpf: '',
      celular: '',
      email: '',
      dataNascimento: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      cep: '',
      planoNome: '',
      planoNumero: '',
      planoData: '',
      senha: '',
      novaSenha: ''
    };

    if(localStorage.getItem('@vevee/usuariocodigo')){
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', () => {
        let state = this.state;
        var returnApi = JSON.parse(xhr.response);

        state.nome = returnApi[0].nome;
        state.email = returnApi[0].email;
        state.cpf = returnApi[0].cpf;
        state.celular = returnApi[0].celular;
        state.dataNascimento = returnApi[0].dataNascimento;
        state.endereco = returnApi[0].endereco;
        state.numero = returnApi[0].numero;
        state.complemento = returnApi[0].complemento;
        state.bairro = returnApi[0].bairro;
        state.cidade = returnApi[0].cidade;
        state.estado = returnApi[0].estado;
        state.cep = returnApi[0].cep;
        state.planoNome = returnApi[0].planoNome;
        state.planoNumero = returnApi[0].planoNumero;
        state.planoData = returnApi[0].planoData;
        this.setState(state);
  
      });
      xhr.open('GET', 'https://app.vevee.com.br/apiinterna/perfil?codigo=' + localStorage.getItem('@vevee/usuariocodigo'));
      xhr.send();
    }
    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.letraMaiuscula = this.letraMaiuscula.bind(this);
  }

  letraMaiuscula(string){
    return string.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }

  handleSubmit(event) {
    event.preventDefault();
    let state = this.state;

    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      
      var returnApi = JSON.parse(xhr.response);
      
      Alert.closeAll();
      if(returnApi[0].status == false){
        Alert.error(returnApi[0].mensagem, { position: 'top', effect: 'slide', timeout: 'none' });
      }
      else{
        localStorage.setItem('@vevee/usuarionome', state.nome);
        Alert.success('Perfil atualizado.', { position: 'top', effect: 'slide', timeout: 'none' });
      }

    });
    xhr.open('GET', 'https://app.vevee.com.br/apiinterna/perfil?codigo=' + localStorage.getItem('@vevee/usuariocodigo') + '&nome=' + state.nome + '&cpf=' + state.cpf + '&celular=' + state.celular + '&dataNascimento=' + state.dataNascimento + '&endereco=' + state.endereco + '&numero=' + state.numero + '&complemento=' + state.complemento + '&bairro=' + state.bairro + '&cidade=' + state.cidade + '&estado=' + state.estado + '&cep=' + state.cep + '&planoNome=' + state.planoNome + '&planoNumero=' + state.planoNumero + '&planoData=' + state.planoData + '&senha=' + state.novaSenha);
    xhr.send();
  }

  render(){
      return(
        <>
          <NavPerfil />

          <div id="baseContainer">
            <Container>
              <nav>
                <ul>
                  <li><Link to={'./minhasconsultas'}><FaCalendar /> Consultas</Link></li>
                  <li><Link to={'./meusdependentes'}><FaUsers /> Dependentes</Link></li>
                  <li><Link to={'./meuscartoes'}><FaCreditCard /> Meios de Pagamento</Link></li>
                  <li><Link to={'./minhacarteirinha'}><FaIdCard /> Carteirinha</Link></li>
                  <li><Link to={'./meuperfil'}><FaUser /> Perfil</Link></li>
                  <li style={{borderTop: '1px solid #CCC', marginTop: '10px', paddingTop: '20px'}}><Link to={'./login'}><FaTimes /> Sair</Link></li>
                </ul>
              </nav>

              <div id="col-perfil">
                <h1>Perfil</h1>
              </div>
              <br clear="all" />

              <div id="col-form">
                <Form onSubmit={this.handleSubmit}>
                  <h1 className="titulo">Dados Pesssoais</h1>
                  <div>
                    <label>Nome Completo</label>
                    <InputMask type="text" placeholder="Nome" value={this.state.nome} onChange={(e) => this.setState({nome: e.target.value})} required />
                  </div>
                  <div>
                    <label>E-mail</label>
                    <InputMask type="text" placeholder="E-mail" value={this.state.email} disabled style={{textTransform: 'lowercase'}} />
                  </div>

                  <div className="col-lef-50">
                    <label>CPF</label>
                    <InputMask type="text" placeholder="CPF" value={this.state.cpf} disabled onChange={(e) => this.setState({cpf: e.target.value})} mask="999.999.999-99" required />
                  </div>
                  <div className="col-rig-50">
                    <label>Nascimento</label>
                    <InputMask type="text" placeholder="Data de Nascimento" value={this.state.dataNascimento} onChange={(e) => this.setState({dataNascimento: e.target.value})} mask="99/99/9999" required />
                  </div>

                  <div className="col-lef-50">
                    <label>Celular</label>
                    <InputMask type="text" placeholder="Celular" value={this.state.celular} onChange={(e) => this.setState({celular: e.target.value})} required />
                  </div>

                  <br clear="all" />
                  <h1 className="titulo">Endereço</h1>
                  <div>
                    <label>Endereço</label>
                    <InputMask type="text" placeholder="Endereço" value={this.state.endereco} onChange={(e) => this.setState({endereco: e.target.value})} required /><br />
                  </div>

                  <div className="col-lef-50">
                    <label>Número</label>
                    <InputMask type="text" placeholder="Número" value={this.state.numero} onChange={(e) => this.setState({numero: e.target.value})} required />
                  </div>
                  <div className="col-rig-50">
                    <label>Complemento</label>
                    <InputMask type="text" placeholder="Complemento" value={this.state.complemento} onChange={(e) => this.setState({complemento: e.target.value})} />
                  </div>

                  <div className="col-lef-50">
                    <label>Bairro</label>
                    <InputMask type="text" placeholder="Bairro" value={this.state.bairro} onChange={(e) => this.setState({bairro: e.target.value})} required />
                  </div>
                  <div className="col-rig-50">
                    <label>CEP</label>
                    <InputMask type="text" placeholder="CEP" value={this.state.cep} onChange={(e) => this.setState({cep: e.target.value})} required mask="99999-999" />
                  </div>

                  <div className="col-lef-50">
                    <label>Cidade</label>
                    <InputMask type="text" placeholder="Cidade" value={this.state.cidade} onChange={(e) => this.setState({cidade: e.target.value})} required />
                  </div>
                  <div className="col-rig-50">
                    <label>Estado</label>
                    <InputMask type="text" placeholder="Estado" value={this.state.estado} onChange={(e) => this.setState({estado: e.target.value})} required style={{ textTransform: 'uppercase' }} maxLength="2" />
                  </div>
                  <SubmitButton>Salvar</SubmitButton><br /><br /><br />
                </Form>
              </div>    

              <div id="col-form">
                <h1 className="titulo">Alterar senha</h1>

                <Form onSubmit={this.handleSubmit}>
                  <div>
                    <label>Nova Senha</label>
                    <InputMask type="password" placeholder="Nova Senha" value={this.state.novaSenha} onChange={(e) => this.setState({novaSenha: e.target.value})} />
                  </div>

                  <SubmitButton>Alterar senha</SubmitButton>

                  <br clear="all" />
                  <h1 className="titulo">Plano de Saúde</h1>
                  <div>
                    <label>Plano</label>
                    <InputMask type="text" placeholder="Plano" value={this.state.planoNome} onChange={(e) => this.setState({planoNome: e.target.value})} /><br />
                  </div>

                  <div className="col-lef-50">
                    <label>Núm. Carteira</label>
                    <InputMask type="text" placeholder="Núm. Carteira" value={this.state.planoNumero} onChange={(e) => this.setState({planoNumero: e.target.value})} />
                  </div>
                  <div className="col-rig-50">
                    <label>Data de Validade</label>
                    <InputMask type="text" placeholder="Data de Validade" value={this.state.planoData} onChange={(e) => this.setState({planoData: e.target.value})} mask="99/99/9999" />
                  </div>
                  <SubmitButton>Salvar</SubmitButton>
                  <br /><br />
                </Form>
              </div>
            </Container>
            <Alert stack={{limit: 3}} />
          </div>
        </>
      );
  }

}

export default MeuPerfil;